<template>
  <section class="registr-new login__body-background-image">
    <div class="registr-wrapper">
      <div class="register login__container">
        <div class="login__content">
          <div class="login__content__title__padding">
            <img src="@/assets/img/m-login/login_logo.svg" alt="logo" class="login__content__title__padding_img" />
          </div>
          <div class="registr">
            <h1>Регистрация</h1>
            <form @submit.prevent="registration" method="post">
              <div class="login__content__form register__content__form__padding">

                <div class="register__content__form">
                  <label for="email" v-if="!errors.email">Email</label>
                  <label for="email" v-if="errors.email" class="error-message">{{ errors.email }}</label>
                  <input v-model="form.email" type="email" id="email" placeholder="Введите email" />
                </div>

                <div class="register__content__form">
                  <label for="password" v-if="!errors.password">Пароль</label>
                  <label for="password" v-if="errors.password" class="error-message">{{ errors.password }}</label>
                  <input v-model="form.password" type="password" id="password" placeholder="Введите пароль" />
                </div>

                <div class="register__content__form">
                  <label for="password_confirmation" v-if="!errors.password_confirmation">Повторите пароль</label>
                  <label for="password_confirmation" v-if="errors.password_confirmation" class="error-message">{{
                    errors.password_confirmation }}</label>
                  <input v-model="form.password_confirmation" type="password" id="password_confirmation"
                    placeholder="Введите пароль" />
                </div>

                <div class="register__content__form">
                  <label for="name" v-if="!errors.name">Ф.И.О.</label>
                  <label for="name" v-if="errors.name" class="error-message">{{ errors.name }}</label>
                  <input v-model="form.name" type="text" id="name" placeholder="Введите Ф.И.О." />
                </div>

                <div class="register__content__form">
                  <label for="position" v-if="!errors.position">Должность</label>
                  <label for="position" v-if="errors.position" class="error-message">{{ errors.position }}</label>
                  <input v-model="form.position" type="text" id="position" placeholder="Введите должность" />
                </div>

                <button type="submit">Зарегистрироваться</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="link">
      <router-link :to="{ name: 'MCalendar' }">To Calendar</router-link>
      <router-link :to="{ name: 'MLogin' }">To Login</router-link>
    </div> -->
    <Footer />
  </section>
</template>

<script setup>
import { useStore } from 'vuex';
import { reactive, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Footer from './footer.vue';

const store = useStore();
const router = useRouter();
const route = useRoute();

const form = reactive({
  name: '',
  email: '',
  position: '',
  password: '',
  password_confirmation: '',
  token: ''
});

const errors = reactive({
  email: '',
  password: '',
  password_confirmation: '',
  name: '',
  position: ''
});

const validateForm = () => {
  let isValid = true;

  const emailPattern = /^[a-zA-Z0-9.]{2,}@[a-zA-Z0-9]{2,}\.[a-zA-Z]{2,}$/;
  if (form.email.length > 20 || !emailPattern.test(form.email)) {
    errors.email = `Email вида xx@xx.xx длиной от 8 до 20 символов, сейчас: ${form.email.length}`;
    isValid = false;
  } else {
    errors.email = '';
  }

  const passwordPattern = /^[a-zA-Z0-9]{8,20}$/;
  if (!passwordPattern.test(form.password)) {
    errors.password = `Пароль латиницей и цифрами от 8 до 20 символов, сейчас: ${form.password.length}`;
    isValid = false;
  } else {
    errors.password = '';
  }
  if (form.password_confirmation !== form.password) {
    errors.password_confirmation = `Пароль нужно повторить, сейчас пароли отличаются`;
    isValid = false;
  } else {
    errors.password_confirmation = '';
  }

  const namePattern = /^[А-Яа-яЁё\s]{10,150}$/;
  if (!namePattern.test(form.name)) {
    errors.name = `Ф.И.О. на русском от 10 до 150 символов, сейчас: ${form.name.length}`;
    isValid = false;
  } else {
    errors.name = '';
  }

  const positionPattern = /^[А-Яа-яЁё\s]{5,150}$/;
  if (!positionPattern.test(form.position)) {
    errors.position = `Должность на русском от 5 до 150 символов, сейчас: ${form.position.length}`;
    isValid = false;
  } else {
    errors.position = '';
  }

  return isValid;
};

onMounted(() => {
  form.token = route.query.token || '';
});

const registration = async () => {
  if (!validateForm()) {
    return;
  }

  try {
    const responseData = await store.dispatch('registration', form);
    if (responseData.status === 201) {
      alert('Вы успешно зарегистрировались!');
      router.push('/');
    }
  } catch (error) {
    alert('Вы не можете зарегистрироваться, получите ссылку у администратора.');
  }
};
</script>

<style scoped>
.error-message {
  color: var(--main-blue-color);
  font-weight: 500;
}
</style>