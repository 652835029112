<template>
  <div class="newheader__left__token newheader__left__token__padding">
    <form @submit.prevent="handleSubmit">
      <div class="newheader__left__token__block">
        <input
          :value="random_token"
          @input="updateToken($event.target.value)"
          type="text"
        />
        <button type="submit" class="newheader__left__token__gen">
          Получить ссылку приглашения
        </button>
        <div
          class="newheader__left__token__gen newheader__left__token__gen_a_div"
          v-if="linkVisible && link"
        >
          <a
            @click="copyLinkToClipboard"
            class="newheader__left__token__gen newheader__left__token__gen_a_link"
            :href="link"
            >Скопировать ссылку</a
          >
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "MBlockLeftMenuLinks",
  props: {
    get_reference: {
      type: Function,
      required: true,
    },
    random_token: {
      type: String,
      default: "",
    },
    link: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      linkVisible: false,
      isLinkReady: false,
    };
  },
  watch: {
    link(newLink) {
      this.isLinkReady = !!newLink;
    },
  },
  emits: ["update:random_token"],
  methods: {
    updateToken(value) {
      this.$emit("update:random_token", value);
    },
    handleSubmit() {
      this.get_reference();
      this.linkVisible = true;
    },
    async copyLinkToClipboard(event) {
      event.preventDefault();
      if (this.isLinkReady && this.link) {
        const fullLink = this.link;
        if (navigator.clipboard) {
          try {
            await navigator.clipboard.writeText(fullLink);
            alert(
              "Ссылка для регистрации пользователя скопирована в буфер обмена"
            );
          } catch (err) {
            console.error("Ошибка при копировании ссылки: ", err);
          }
        } else {
          this.fallbackCopyTextToClipboard(fullLink);
        }
      } else {
        alert("Ссылка еще не готова, подождите немного");
      }
    },
    fallbackCopyTextToClipboard(text) {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand("copy");
        alert("Ссылка для регистрации скопирована через резервное решение.");
      } catch (err) {
        console.error("Ошибка при резервном копировании: ", err);
      }
      document.body.removeChild(textArea);
    },
  },
};
</script>
