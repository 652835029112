import { createStore } from "vuex";
import axios from "axios";
import apiClient from "../axios/axios.js";

export default createStore({
  state: {
    user_data: {},
    access_token: localStorage.getItem("access_token") || "",
    role: localStorage.getItem("role") || "",
    user_name: localStorage.getItem("user_name") || "Сотрудник",
    user_id: localStorage.getItem("user_id") || "",
    link_created: "",
    data__created: [],
    cards: [],
    calendar_month: [
      {
        month: "Январь",
        days: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
        ],
      },
      {
        month: "Февраль",
        days: [
          "",
          "",
          "",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
        ],
      },
      {
        month: "Март",
        days: [
          "",
          "",
          "",
          "",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
        ],
      },
      {
        month: "Апрель",
        days: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
        ],
      },
      {
        month: "Май",
        days: [
          "",
          "",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
        ],
      },
      {
        month: "Июнь",
        days: [
          "",
          "",
          "",
          "",
          "",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
        ],
      },
      {
        month: "Июль",
        days: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
        ],
      },
      {
        month: "Август",
        days: [
          "",
          "",
          "",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
        ],
      },
      {
        month: "Сентябрь",
        days: [
          "",
          "",
          "",
          "",
          "",
          "",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
        ],
      },
      {
        month: "Октябрь",
        days: [
          "",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
        ],
      },
      {
        month: "Ноябрь",
        days: [
          "",
          "",
          "",
          "",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
        ],
      },
      {
        month: "Декабрь",
        days: [
          "",
          "",
          "",
          "",
          "",
          "",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
        ],
      },
    ],
    send_card: [],
    search_Result: [],
    create_task: [],
    task_user: [],
    show_all_admin_tasks: [],
    concrete_task_author: "",
    concrete_task_participants: [],
    concrete_task_checklist: {},
    concrete_task_comments: {},
    concrete_task_participants_for_comments: {},
    show_all_user_tasks: [],
    selected_participiant: [],
    paggination_links: [],
  },
  getters: {
    getUserId(state) {
      return state.user_id;
    },
    getToken(state) {
      return state.access_token;
    },
    getRole(state) {
      return state.role;
    },
    getUserName(state) {
      return state.user_name;
    },
    getLink(state) {
      return state.link_created;
    },
    getCards(state) {
      return state.cards;
    },
    getPagginationLinks(state) {
      return state.paggination_links;
    },
    getSendCard(state) {
      return state.send_card;
    },
    getSearch(state) {
      return state.search_Result;
    },
    getMonth(state) {
      return state.calendar_month.map((item) => item.month);
    },
    getDays(state) {
      return state.calendar_month.map((item) => item.days);
    },
    getTask(state) {
      return state.create_task;
    },
    getTaskUser(state) {
      return state.task_user || [];
    },
    getShowAdminTasks(state) {
      return state.show_all_admin_tasks;
    },
    getConcreteTaskAuthor(state) {
      return state.concrete_task_author;
    },
    getConcreteTaskParticipants(state) {
      return state.concrete_task_participants;
    },
    getConcreteTaskChecklist(state) {
      return state.concrete_task_checklist;
    },
    getConcreteTaskComments(state) {
      return state.concrete_task_comments;
    },
    getConcreteTaskParticipantsForComments(state) {
      return state.concrete_task_participants_for_comments;
    },
    getShowUserTasks(state) {
      return state.show_all_user_tasks;
    },
    getSelectParticipiant(state) {
      return state.selected_participiant;
    },
  },
  mutations: {
    set_User_data(state, payload) {
      state.user_data = payload.data;
      state.access_token = payload.token;
      state.role = payload.role;
      state.user_name = payload.user_name;
      state.user_id = payload.user_id;

    },
    set_Token(state, payload) {
      state.access_token = payload;
    },
    set_Role(state, payload) {
      state.role = payload;
    },
    set_User_name(state, payload) {
      state.user_name = payload;
    },
    set_User_link(state, payload) {
      state.link_created = payload.link;
    },
    set_User_disapr(state, payload) {
      state.data__created = payload.data_cre;
    },
    set_Cards_approved(state, payload) {
      //console.log('Setting approved cards:', payload);
      state.send_card = payload;
    },
    set_Paggination_approved(state, payload) {
      // console.log("Setting paggination links:", payload);
      state.paggination_links = payload;
    },
    set_Cards(state, payload) {
      state.cards = payload;
    },
    remove_User(state, cardId) {
      state.cards = state.cards.filter((card) => card.id !== cardId);
    },
    remove_User_approved(state, cardId) {
      state.send_card = state.send_card.filter((card) => card.id !== cardId);
    },
    set_Search_user(state, payload) {
      state.search_Result = payload;
    },
    set_Search_task(state, payload) {
      state.search_Result = payload;
    },
    set_Create_task(state, payload) {
      state.create_task = payload;
    },
    set_Task_user(state, users) {
      state.task_user = users;
    },
    set_All_admin_tasks(state, payload) {
      state.show_all_admin_tasks = payload;
    },
    set_Concrete_task_author(state, payload) {
      state.concrete_task_author = payload;
    },
    set_Concrete_task_participants(state, payload) {
      state.concrete_task_participants = payload;
    },
    set_Concrete_task_checklist(state, payload) {
      state.concrete_task_checklist = payload;
    },
    set_Concrete_task_comments(state, payload) {
      state.concrete_task_comments = payload;
    },
    set_Concrete_task_participants_for_comments(state, payload) {
      state.concrete_task_participants_for_comments = payload;
    },
    set_All_user_tasks(state, payload) {
      state.show_all_user_tasks = payload;
    },
    set_Participiant(state, participant) {
      state.selected_participiants.push(participant);
    },
    set_Participiant_Clear(state) {
      state.selected_participiants = state.selected_participiant.filter(
        (item) => item !== participant
      );
    },
    set_User_registration_data(state, payload) {
      state.user_data = payload.user_data;
      state.access_token = payload.access_token;
      state.role = payload.role;
    },
    addCommentToTask(state, comment) {
      state.concrete_task_comments.push(comment);
    },
  },
  actions: {

    async fetchUserData({ commit }, form) {
      try {
        const response = await apiClient.post("/login", form);    
        const { user_data, access_token, role, user_name, user_id } = response.data;
        commit("set_User_data", {
          data: user_data,
          token: access_token,
          role: role,
          user_name: user_name,
          user_id: user_id,
        });
        localStorage.setItem("access_token", access_token);
        localStorage.setItem("role", role);
        localStorage.setItem("user_name", user_name);
        localStorage.setItem("user_id", user_id);
        return response;
      } catch (error) {
        console.error("Failed to fetch user data:", error);
      }
    },

    async recieveUserToken({ commit }) {
      try {
        const response = await apiClient.post("/admin/generate-register-link");
        commit("set_User_link", {
          link: "https://oblakozadach.ru/MRegistr?token=" + response.data.link_created,
        });
      } catch (error) {
        console.error("Failed to create link:", error);
      }
    },
    async log_Out({ commit }) {
      commit("set_User_data", { data: {}, token: "", role: "" });
      commit("set_User_link", "");
      commit("set_Cards", []);
      commit("set_User_disapr", []);
      localStorage.removeItem("access_token");
      localStorage.removeItem("role");
      localStorage.removeItem("user_name");
      localStorage.removeItem("user_id");
    },
    async fetchCards({ commit }, link = "") {
      // Экшн для загрузки карточек
      try {
        if (link === "") {
          const response = await apiClient.get(
            "/admin/show-is-not-approved-users"
          );
          commit("set_Cards", response.data.data);
        } else {
          const response = await apiClient.get(link);
          commit("set_Cards", response.data.data);
        }
      } catch (error) {
        console.error("Failed to fetch cards:", error);
      }
    },
    async fetchPaggination({ commit }, link = "") {
      try {
        if (link === "") {
          const response = await apiClient.get(
            "/admin/show-is-not-approved-users"
          );
          commit("set_Paggination_approved", response.data.links);
        } else {
          const response = await apiClient.get(link);
          commit("set_Paggination_approved", response.data.links);
        }
      } catch (error) {
        console.error("Failed to fetch cards:", error);
      }
    },
    async fetchCards_Approved({ commit }, link = "") {
      try {
        if (link === "") {
          const response = await apiClient.get("/admin/show-is-approved-users");
          commit("set_Cards_approved", response.data.data);
        } else {
          const response = await apiClient.get(link);
          commit("set_Cards_approved", response.data.data);
        }
      } catch (error) {
        console.error("Failed to fetch cards:", error);
      }
    },
    async fetchPaggination_Approved({ commit }, link = "") {
      try {
        if (link === "") {
          const response = await apiClient.get("/admin/show-is-approved-users");
          commit("set_Paggination_approved", response.data.links);
        } else {
          const response = await apiClient.get(link);
          commit("set_Paggination_approved", response.data.links);
        }
      } catch (error) {
        console.error("Failed to fetch cards:", error);
      }
    },

    async registration({ commit }, form) {
      try {
        const { token, ...rest } = form;
        const response = await apiClient.post(`/register/${token}`, rest);
        commit("set_User_registration_data", response.data);
        return response;
      } catch (error) {
        error = null;
        throw error;
      }
    },

    async update_Confirmed_User({ commit }, workerData) {
      try {
        const userDataUpdate = {
          name: workerData.name ? `name=${workerData.name}&` : "",
          email: workerData.email ? `email=${workerData.email}&` : "",
          password: workerData.password
            ? `password=${workerData.password}&`
            : "",
          password_confirmation: workerData.password_confirmation
            ? `password_confirmation=${workerData.password_confirmation}&`
            : "",
          position: workerData.position
            ? `position=${workerData.position}&`
            : "",
        };
        const userDataForChange = Object.values(userDataUpdate).join("");
        if (userDataForChange) {
          const response = await apiClient.patch(
            `/admin/update/user/${workerData.id}?${userDataForChange}`
          );
          return response.data;
        } else {
          alert("Нечего обновлять");
          return false;
        }
      } catch (e) {
        // alert("Увы, обновить данные не удалось :(( " + e);
      }
    },

    async searchUser({ commit }, query) {
      try {
        const response = await apiClient.get(
          `/admin/search/not-approved/user`,
          {
            params: { query: query },
          }
        );
        // Передаем массив данных в мутацию
        commit("set_Search_user", response.data); // Передаем весь массив в мутацию
      } catch (error) {
        console.error(
          "Failed to search:",
          error.response ? error.response.data : error
        );
      }
    },

    async searchTask({ commit }, { query, type = true }) {
      try {
        const response = await apiClient.get(`/task/search/all`, {
          params: { query: query },
        });
        // console.log(`ЗАПРОС: `, query);
        // console.log(`ОТВЕТ: `, response);
        if (String(response.data[0].name) === "Not found") {
          // console.log(`БЕЗ ФИЛЬТРА: `, response.data);
          commit("set_Search_task", response.data);
        } else {
          let tasks = response.data.filter(
            (task) => task.is_completed === type
          );
          // console.log(`ПОСЛЕ ФИЛЬТРА:`, tasks);
          if (tasks.length == 0) {
            tasks = [{ name: ["Not found"] }];
          }
          // console.log(`ПОСЛЕ возможного ФИЛЬТРА:`, tasks);
          commit("set_Search_task", tasks);
        }
      } catch (error) {
        console.error(
          "Failed to search:",
          error.response ? error.response.data : error
        );
      }
    },

    async searchApprovedUsers({ commit }, query) {
      try {
        const response = await apiClient.get(`/admin/search/approved/user`, {
          params: { query: query },
        });
        // Передаем массив данных в мутацию
        commit("set_Search_user", response.data); // Передаем весь массив в мутацию
      } catch (error) {
        console.error(
          "Failed to search:",
          error.response ? error.response.data : error
        );
      }
    },
    async add_To_confirm({ commit }, form) {
      try {
        const response = await apiClient.patch("/admin/approved", null, {
          params: {
            name: form.name,
            email: form.email,
          },
        });
        commit("set_Cards_approved", response.data.data);
      } catch (error) {
        console.error("Ошибка при отправке запроса:", error);
      }
    },
    async deleteCard({ commit }, cardId) {
      try {
        const response = await apiClient.delete(`/admin/delete/user/${cardId}`);
        commit("remove_User", cardId);
      } catch (error) {
        console.error("Failed to delete:", error);
      }
    },
    async deleteCardfromConfirm({ commit }, cardId) {
      try {
        const response = await apiClient.delete(`/admin/delete/user/${cardId}`);
        commit("remove_User_approved", cardId);
      } catch (error) {
        console.error("Failed to delete:", error);
      }
    },

    /* Создание задачи (название, описание, сроки, исполнители, список подзадач, файл + пустой комментарий) */
    async createTask({ commit }, { form, file }) {
      try {
        const formData = new FormData();
        if (file) {
          formData.append('files[]', file);
        }
        const response = await apiClient.post(`/task/create?${form}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        if (response.data.message === "Task created")
          alert("Поздравляем, задача создана!");
      } catch (error) {
        alert("Не удалось создать задачу", error);
      }
    },

    /* Изменение задачи (статус, название, сроки, описание) */
    async updateTask({ commit }, { id, form }) {
      try {
        const response = await apiClient.patch(`/task/update/${id}?${form}`);
        if (response.data.message === "Task updated successfully") {
          alert("Задача изменена!");
          window.location.reload();
        }
      } catch (error) {
        alert("Не удалось изменить задачу", error);
      }
    },

    /* Отправка комментария к задаче */
    async submitComment({ commit }, { taskId, commentText, userId }) {
      try {
        if (commentText.trim() === "") {
          return;
        }
        const response = await apiClient.post(
          `comment/create?task_id=${taskId}&comment=${commentText}`
        );
        if (response.data.message === "Comment created") {
          const newComment = {
            author: userId,
            text: commentText
          };
          commit("addCommentToTask", newComment);
          console.log("Комментарий добавлен!")
          // alert("Комментарий добавлен!");
        } else {
          alert("Отправить комментарий не удалось");
        }
      } catch (error) {
        console.log("Ошибка при отправке комментария", error);
      }
    },

    /* Загрузка файла в виде комментария к созданной задаче */
    async addFileToTask({ commit }, { taskId, file, userId }) {
      try {
        const formData = new FormData();
        if (file) formData.append('files[]', file);
        const response = await apiClient.post(`comment/create?task_id=${taskId}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        if (response.data.message === "Comment created") {
          alert("Файл добавлен!");
          window.location.reload();
        } else alert("Добавить файл не удалось");
      } catch (error) {
        console.log("Ошибка при добавлении файла", error);
      }
    },

    async showUserTask({ commit }) {
      try {
        const response = await apiClient.get(`/task/show-user`);
        commit("set_Task_user", response.data);
        // вызов мутации для обновления task_user
      } catch (error) {
        console.error("Failed to show user", error);
      }
    },

    async showAllAdminTasks({ commit }, { isCompleted, link = "" }) {
      const filterNewestTask = "filterFirst=newest&";
      try {
        if (link === "") {
          const response = await apiClient.get(
            `/task/show?${filterNewestTask}`
          );
          // console.log(response.data);
          const tasks = response.data.data.filter(
            (task) => task.is_completed === isCompleted
          );
          commit("set_All_admin_tasks", tasks);
        } else {
          link = link.split("");
          // console.log(link);
          let filter = filterNewestTask.split("");
          // console.log(filter);
          link.splice(link.indexOf("?") + 1, 0, ...filter);
          // console.log(link);
          link = link.join("");
          // console.log(link);
          const response = await apiClient.get(link);
          console.log(response);
          const tasks = response.data.data.filter(
            (task) => task.is_completed === isCompleted
          );
          commit("set_All_admin_tasks", tasks);
        }
      } catch (error) {
        console.error("Показать задачи администратору не удалось", error);
      }
    },

    async showConcreteTaskExtraData({ commit }, task) {
      const response = await apiClient.get(`task/show-concrete-task/${task}`);

      const participantsFromConcreteTask = response.data.participants;
      function getValuesExcludingFirstKeyAsString(obj) {
        const keys = Object.keys(obj);
        const values = keys.slice(1).map((key) => obj[key]);
        return values.join(", ");
      }
      const resultParticipants = getValuesExcludingFirstKeyAsString(
        participantsFromConcreteTask
      );

      const checklistItems = response.data.checklist.map((item) => ({
        text: item.text,
        is_selected: item.is_selected,
        task_id: item.task_id,
        checklist_id: item.id,
      }));

      const commentsItems = response.data.comments.map((item) => ({
        author: item.user_id,
        text: item.comment,
        fileName: item.files.length > 0 ? item.files[0].original_name : null,
        fileUrl: item.files.length > 0 ? 'https://oblakozadach.ru:8080' + item.files[0].file_patch : null
      }));
      
      commit(
        "set_Concrete_task_author",
        response.data.participants[Object.keys(response.data.participants)[0]]
      );
      commit("set_Concrete_task_participants", resultParticipants);
      commit("set_Concrete_task_checklist", checklistItems);
      commit("set_Concrete_task_comments", commentsItems);
      commit("set_Concrete_task_participants_for_comments", response.data.participants);
    },

    async updateConcreteTaskChecklistStatus({ commit }, task) {
      const response = await apiClient.patch(
        `/checklist/update?task_id=${task.task_id}&checklist_id=${
          task.checklist_id
        }&is_selected=${task.is_selected * 1}`
      );
      if (response.data.message === "checklist update" && task.is_selected) {
        alert("Подзадача выполнена!");
      } else {
        alert("Продолжаем выполнять подзадачу");
      }
    },

    async fetchPaggination_AdminTasks({ commit }, link = "") {
      const filterNewestTask = "filterFirst=newest&";
      try {
        if (link === "") {
          const response = await apiClient.get(
            `/task/show?${filterNewestTask}`
          );
          commit("set_Paggination_approved", response.data);
        } else {
          link = link.split("");
          // console.log(link);
          let filter = filterNewestTask.split("");
          // console.log(filter);
          link.splice(link.indexOf("?") + 1, 0, ...filter);
          // console.log(link);
          link = link.join("");
          // console.log(link);
          const response = await apiClient.get(link);
          commit("set_Paggination_approved", response.data);
        }
      } catch (error) {
        console.error("Failed to fetch cards:", error);
      }
    },
    async showAllUserTasks({ commit }, { isCompleted, link = "" }) {
      const filterNewestTask = "filterFirst=newest&";
      try {
        if (link === "") {
          const response = await apiClient.get(
            `/task/show?${filterNewestTask}`
          );
          // console.log(response.data);
          const tasks = response.data.data.filter(
            (task) => task.is_completed === isCompleted
          );
          commit("set_All_user_tasks", tasks);
        } else {
          link = link.split("");
          // console.log(link);
          let filter = filterNewestTask.split("");
          // console.log(filter);
          link.splice(link.indexOf("?") + 1, 0, ...filter);
          // console.log(link);
          link = link.join("");
          // console.log(link);
          const response = await apiClient.get(link);
          // console.log(response);
          const tasks = response.data.data.filter(
            (task) => task.is_completed === isCompleted
          );
          commit("set_All_user_tasks", tasks);
        }
      } catch (error) {
        console.error("Показать задачи пользователю не удалось", error);
      }
    },

    add_Patricipiant({ commit }, participant) {
      commit("set_Participiant", participant);
    },
    clear__Patricipiant({ commit }, participant) {
      commit("set_Participiant_Clear", participant);
    },

    async deleteTask({ commit }, task) {
      try {
        const answer = prompt(
          `Для удаления этой задачи укажите ее номер: ${task}`
        );
        if (answer === "" + task) {
          const response = await apiClient.delete(`/task/delete/${task}`);
          if (response.data.message === "Task delete successfully") {
            alert(`Задача № ${task} удалена`);
            window.location.reload();
          }
        } else if (answer !== null) {
          alert("Неправильный номер задачи - задача остается");
        }
      } catch (error) {
        console.error(`Удаление задачи № ${task} не удалось: `, error);
      }
    },
  },
  modules: {},
});
