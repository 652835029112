<template>
    <div class="newheader__left__bottom__track newheader__left__bottom__track__padding">
        <router-link :to="{ name: 'MAssignTask' }" class="custom-link">
            <div class="newheader__left__bottom__track__block newheader__left__bottom__no_marker">
                <span>Назначить задачу</span>
                <img src="@/assets/img/left_plus.svg" alt="left_plus" />
            </div>
        </router-link>
        <router-link :to="{ name: 'MCompleted' }" class="custom-link">
            <div class="newheader__left__bottom__track__block newheader__left__bottom__no_marker">
                <span>Выполненные</span>
                <img src="@/assets/img/left_check.svg" alt="left_check" />
            </div>
        </router-link>
        <router-link :to="{ name: 'MUnfulfield' }" class="custom-link">
            <div class="newheader__left__bottom__track__block newheader__left__bottom__no_marker">
                <span>Невыполненные</span>
                <img src="@/assets/img/left_close.svg" alt="left_close" />
            </div>
        </router-link>
        <router-link :to="{ name: 'MConfirm' }" class="custom-link">
            <div class="newheader__left__bottom__track__block newheader__left__bottom__marker">
                <span>Сотрудники</span>
                <img src="@/assets/img/left_people.svg" alt="left_people" />
            </div>
        </router-link>
    </div>
</template>

<script>
export default {
    name: 'MBlockLeftMenuLinks'
};
</script>