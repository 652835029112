<template>
  <!-- Классы пока аналогичны классам элементов страницы Логин -->
  <section class="login__body-background-image">
    <div class="login login__container page-404_block">
      <img src="@/assets/img/m-404/404-logo.svg" alt="404">
      <p class="page-404_block__title">Произошла ошибка, возможно неправильно введен адрес ссылки, чтобы вернуться в
        систему нажмите кнопку “Перейти”</p>
      <button class="page-404_block__btn" @click="goToLoginPage">Перейти</button>
    </div>
    <div class="footer">
      <p class="made">Сделано в:</p>
      <img class="sous__img" src="@/assets/img/souz.svg" alt="logo" />
      <img class="line__img" src="@/assets/img/Line 1.svg" alt="l" />
      <p class="icrew">iCrew</p>
    </div>
  </section>
  <router-view />
</template>

<script>
import Footer from './footer.vue';
export default {
  methods: {
    goToLoginPage() {
      this.$router.replace("/");
    },
  },
};
</script>
<style scoped>
  .footer {
    display: flex;
    flex-direction: row;
    gap: 7px;
    justify-content: end;
    padding-right: 29px;
    padding-bottom: 26px;
  }
  
  .made {
    width: 94px;
    height: 17px;
    font-family: 'Inter', sans-serif; 
    font-weight: 500;
    font-size: 16px;
    line-height: 19.36px;
    color: white;
    padding-right: 5px;
    padding-top: 2px;
  }

  .icrew {
    width: 51px;
    height: 22px;
    font-family: 'Inter', sans-serif; 
    font-weight: 600;
    font-size: 18px;
    line-height: 21.78px;
    color: white;
    padding-top: 2px;
  }

  @media (max-width: 1024px) {
    .footer {
        justify-content: center;
        padding-right: 0px;
    }

    .made {
        width: 69px;
        height: 13px;
        font-size: 12px;
        line-height: 14.52px;
        padding-right: 5px;
        padding-top: 0px;
    }

    .icrew {
        width: 34px;
        height: 15px;
        font-size: 12px;
        line-height: 14.52px;
        padding-top: 0px;
    }
    .sous__img {
        width: 73px;
        height: 11px;
        margin-top: 1px;
    }

    .line__img {
        height: 11px;
        margin-top: 1px;
    }
}
</style>