<template>

<div class="footer">
    <p class="made">Сделано в:</p>
    <a href="https://xn--g1ani7c.xn--p1ai/" target="_blank" rel="noopener noreferrer">
        <img class="sous__img" src="@/assets/img/souz_mobile.svg" alt="logo" />
    </a>
    <img class="line__img" src="@/assets/img/Line 1.svg" alt="l" />
    <p class="icrew">iCrew</p>
</div>

</template>
  
  <script>

  export default {
    name: 'FooterLeft',
    data() {
      return {};
    },
    computed: {},
    mounted() {},
    methods: {}
  };
  </script>
  <style scoped>
  .footer {
    display: flex;
    flex-direction: row;
    gap: 7px;
    justify-content: center;
    padding-right: 0px;
    position: absolute;
    bottom: 20px;
    left: 128px;
    z-index: 1000;

  }
  
  .made {
    font-family: 'Inter', sans-serif; 
    font-weight: 500;
    color: white;
    width: 69px;
    height: 13px;
    font-size: 12px;
    line-height: 14.52px;
    padding-right: 5px;
    padding-top: 0px;
  }

  .icrew {

    font-family: 'Inter', sans-serif; 
    font-weight: 600;
    color: white;
    font-size: 12px;
    line-height: 14.52px;
    padding-top: 0px;
  }
  .sous__img {
        width: 73px;
        height: 11px;
        margin-top: 1px;
    }

    .line__img {
        height: 11px;
        margin-top: 1px;
    }

  @media (max-width: 1375px) {
    .footer {
        padding-right: 0px;
        /* padding-left: 73px; */
        display: flex;
        left: 73px;
     }

    .made {
        width: 69px;
        height: 13px;
        font-size: 12px;
        line-height: 14.52px;
        padding-right: 5px;
        padding-top: 0px;
    }

    .icrew {
        font-family: 'Inter', sans-serif; 
        width: 34px;
        height: 15px;
        font-size: 12px;
        line-height: 14.52px;
        padding-top: 0px;
    }
}

@media (max-width: 1024px) {
    .footer {
        justify-content: center;
        padding-left: 0px;
        padding-bottom: 10px;
        margin-bottom: 0px;
        position: relative ;
        margin-top: auto;
        left: 0px;

    }
}    
  </style>