<template>
  <div class="newheader">
    <!-- УВЕДОМЛЕНИЯ -->
    <div  v-show="toggleNotifications"  class="window_notification">
      <img @click="toggleNotifications = false" src="@/assets/img/left_close.svg" class="close__icon-popup_notification" />
      <VKAllowMessages />
    </div>
    <div class="newheader__left" v-show="mobile_calendar">
      <MBlockLeftLogoTitle />

      <div v-show="mobile_calendar" class="close-img">
        <img @click="mobile_calendar = !mobile_calendar" src="@/assets/img/m-tasks/close.svg" class="close__icon" />
      </div>

      <div class="newheader__left__bottom newheader__left__bottom__padding">
        <div class="newheader__left__bottom__calendar newheader__left__bottom__calendar__padding newheader__container">
          <div class="newheader__left__bottom__calendar_top newheader__left__bottom__calendar_top__padding">
            <div class="newheader__left__month">
              <span> {{ currentMonthName }} {{ currentYear }}</span>
            </div>
            <div class="newheader__left__flex">
              <img @click="previous_month" class="newheader__left__arrow" src="@/assets/img/left_arrow.svg"
                alt="left_arrow" />
              <img @click="next_month" class="newheader__left__arrow" src="@/assets/img/right_arrow.svg"
                alt="right_arrow" />
            </div>
          </div>
          <div class="newheader__left__calendar">
            <div class="newheader__left__day__names">П</div>
            <div class="newheader__left__day__names">В</div>
            <div class="newheader__left__day__names">С</div>
            <div class="newheader__left__day__names">Ч</div>
            <div class="newheader__left__day__names">П</div>
            <div class="newheader__left__day__names">С</div>
            <div class="newheader__left__day__names">В</div>
            <div v-for="(item, index) in calendarDays" :key="index" 
            :class="{'today': item === day  && currentMonth == month && currentYear === year}">
              <span v-if="item">{{ item }}</span>
            </div>
          </div>
        </div>
        <MBlockLeftMenuLinks />

        <MBlockLeftSignupLink :get_reference="get_reference" v-model:random_token="random_token" :link="link" />
        <div class="distance"></div>
        <FooterLeft />
      </div>
    </div>
    <!-- Левый блок до копирования бургер меню для 375px КОНЕЦ -->

    <div class="newheader__right">
      <!-- Новый блок меню от Дениса НАЧАЛО -->
      <header class="mobile__header">
        <section class="head">
          <img @click="mobile_calendar = !mobile_calendar" src="../assets/img/m-tasks/burger_menu.svg"
            class="burger_menu" />
          <div class="head__logo-container">
            <img @click="stop" src="../assets/img/m-tasks/mobile-cloud.svg" class="head__logo" />
            <p class="head__logo-text">Облако задач</p>
          </div>
          <img @click="logout" src="../assets/img/m-tasks/mobile-logout.svg" class="mob_logout" />
        </section>

        <section class="head-panel__container">
          <div class="head-panel__up-content">
            <div class="head-panel__text">
              <h4 class="head-panel__up-title">Сотрудники</h4>
              <p class="head-panel__date">{{formatted_month_Date}}</p>
            </div>
            <img @click="toggleNotifications= !toggleNotifications" src="@/assets/img/m-tasks/bell.svg" class="head__img bell__img" />
          </div>
          <div class="head-panel__down-content">
            <h4 class="head-panel__down-title">Сегодня</h4>
            <div class="head__img-container">
              <img class="head__img" src="../assets/img/m-tasks/white-search.svg" alt="search_right"
                v-show="!search_open" @click="search_open = !search_open" />
              <div class="head__search__open" v-show="search_open">
                <input class="head__search__open__input" v-model="query" @input="searchInNonConfirm" type="text"
                  placeholder="Начните вводить" />
                <img class="head__img" src="@/assets/img/search_right.svg" alt="search_right"
                  @click="search_open = false" />
              </div>
              <img src="@/assets/img/header__filter640.svg" alt="header__filter" class="head__img filter-img"
                @click="menu_popup = !menu_popup" />
            </div>
          </div>
        </section>
      </header>
      <!-- Новый блок меню от Дениса КОНЕЦ -->

      <!-- Начинается блок меню Сотрудники (dmt) -->
      <div class="newheader__right__empl newheader__right__empl__padding closed-when-mobile">
        <div class="newheader__right__top newheader__right__top__padding">
          <div class="newheader__right__top__block newheader__right__top__block_lk">
            <h2 class="newheader__right__top__title">Сотрудники</h2>
            <div class="newheader__right__top__lk">
              <div @click="toggleNotifications= !toggleNotifications" class="newheader__right__top__wing newheader__right__top__wing__padding1">
                <img  src="../assets/img/wing.svg" class="mob_logout" />
              </div>
              <div class="newheader__right__top__exit newheader__right__top__exit__padding">
                <img @click="logout" src="@/assets/img/exit.svg" />
              </div>
            </div>
          </div>
        </div>
        <div class="newheader__right__bottom">
          <div class="buttons">
            <div class="newheader__right__bottom__block newheader__right__bottom__block__padding closed-when-mobile">
              <div class="newheader__right__bottom__block__flex">
                <button @click="to_confirm" class="newheader__right__top__btn_on">
                  <span>Пользователи в системе</span>
                  <img class="newheader__right__image" src="@/assets/img/check_right.svg" alt="check_right" />
                </button>
              </div>
              <div class="newheader__right__bottom__block__flex">
                <div class="newheader__right__bottom__block__flex newheader__right__bottom__block__fon">
                  <button @click="to_calendar"
                    class="newheader__right__top__btn_on newheader__right__top__btn_on__color newheader__right__top__btn_on__padding newheader__right__top__btn_on_cal">
                    <span>На подтверждение</span>
                    <img class="newheader__right__image" src="@/assets/img/right__plus.svg" alt="right__plus" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="newheader__right__bottom__right newheader__right__bottom__right__padding1">
            <div class="newheader__search__calendar">
              <input v-model="query" @input="searchInNonConfirm" type="text" placeholder="Найти сотрудника" />
              <img class="newheader__right__search" src="@/assets/img/search_right.svg" alt="search_right" />
            </div>
            <div class="newheader__filter newheader__filter__padding newheader__filter__margin">
              <img src="@/assets/img/header__filter.svg" alt="header__filter" @click="menu_popup = !menu_popup" />
            </div>
          </div>
        </div>
      </div>
      <!-- Заканчивается блок меню Сотрудники (dmt) -->
      <!-- ФИЛЬТР МЕНЮ -->
      <section class="menu" v-show="menu_popup" @click="menu_popup = !menu_popup">
        <div class="menu__popup">
          <p class="menu__title">Сортировать</p>
          <button class="menu__btn" @click="sortSendCardByName()">От А до Я</button>
          <button class="menu__btn" @click="sortSendCardById()">По дате</button>
        </div>
      </section>

      <!-- Начинаются кнопки "На подтверждение", "В системе" -->
      <div
        class="newheader__right__bottom__block newheader__right__bottom__block__padding closed-when-desktop opened-when-mobile">
        <div class="newheader__right__bottom__block__flex">
          <button @click="to_confirm" class="newheader__right__top__btn">
            <span>Пользователи в системе</span>
            <img class="newheader__right__image" src="@/assets/img/check_right.svg" alt="check_right" />
          </button>
        </div>
        <div class="newheader__right__bottom__block__flex">
          <button @click="to_calendar" class="newheader__right__top__btn newheader__right__top__btn__color ">
            <span>На подтверждение</span>
            <img class="newheader__right__image" src="@/assets/img/right__plus.svg" alt="right__plus_on" />
          </button>
        </div>
      </div>

      <!-- ПАГИНАЦИЯ (в контейнер все обернутом, чтобы на мобилке свапать порядок элементов) -->
      <section class="paggination-position">
        <div class="tasks-page">
          <button class="tasks-page__number btn-toStart choosen" @click="paggination">
            {{ doubleLeftArrow }}
          </button>
          <button class="tasks-page__number btn-Prev" @click="paggination">
            {{ leftArrow }}
          </button>
          <button class="tasks-page__number btn-Next" @click="paggination">
            {{ rightArrow }}
          </button>
          <button class="tasks-page__number btn-toFinish" @click="paggination">
            {{ doubleRightArrow }}
          </button>
        </div>
        <!-- Закончились кнопки "На подтверждение", "В системе" -->
      <div v-if="notFoundUsers" class="error-message">Нет таких сотрудников...</div>
      <div class="newheader__right__content" v-if="!notFoundUsers">
        <div class="newheader__right__content__block newheader__right__content__block__padding">
          <div v-for="card in query.length >= 3 ? searchResults : (send_card_sorted || cards)" :key="card.id"
            class="newheader__right__content__card newheader__right__content__card__padding">
            <div class="newheader__right__content__card__flex">
              <div class="newheader__right__content__empl">
                <span class="newheader__right__content__title">{{
                  card.name
                  }}</span>
                  <span class="newheader__right__content__text">{{
                    card.position
                  }}</span>
                </div>
                <div class="newheader__right__content__btn newheader__right__content__btn__padding">
                  <button @click="deleteCard(card.id)" class="newheader__right__content__btn__del">
                    Удалить
                  </button>
                  <button @click="go_To_confirm(card.id, card)" class="newheader__right__content__btn__add">
                    Добавить
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script setup>
import FooterLeft from './footer-left.vue';
import MBlockLeftLogoTitle from './block__left_logo-title.vue';
import MBlockLeftMenuLinks from './block__left_menu-links.vue';
import MBlockLeftSignupLink from './block__left_signup-link.vue';

import { ref, computed, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import VKAllowMessages from './m-VKAllowMessages.vue';

const toggleNotifications = ref(false);

const store = useStore();
const router = useRouter();
const random_token = ref("");
const query = ref("");
const cards = computed(() => store.getters.getCards || []);
const link = computed(() => store.getters.getLink);
const token = computed(() => store.getters.getToken);

// Стрелки для пагинации
const doubleLeftArrow = '≪';
const doubleRightArrow = '≫';
const leftArrow = '<';
const rightArrow = '>';

const searchResults = computed(() => store.getters.getSearch || []);
const notFoundUsers = ref(false);

onMounted(() => {
  store.dispatch("fetchCards").then(() => {
    cards.value = store.state.cards || [];
  });
  const token = localStorage.getItem("access_token");
  if (token) {
    store.commit("set_Token", token);
  } else {
    store.dispatch("log_Out");
  }
  store.dispatch("fetchPaggination");
});

const searchInNonConfirm = async () => {
  notFoundUsers.value = false;
  if (query.value.length >= 3) {
    await store.dispatch("searchUser", query.value);
    if (String(searchResults.value[0].name) === 'Not found') {
      searchResults.value[0].name = 'Идёт поиск...';
      notFoundUsers.value = true;
    }
  }
};

const get_reference = async () => {
  try {
    await store.dispatch("recieveUserToken");
    random_token.value = link.value;
  } catch (error) {
    console.error("Error generating registration link:", error);
  }
};

const to_confirm = () => {
  router.push({ name: "MConfirm" });
};

const to_calendar = () => {
  router.push({ name: "MCalendar" });
};

const logout = async () => {
  if (token) {
    store.dispatch("log_Out");
    router.push({ name: "MLogin" });
  } else {
    console.log(error);
  }
};
const go_To_confirm = async (cardId, card) => {
  if (confirm("Вы точно хотите добавить пользователя в систему?")) {
    cards.value = cards.value.filter((card) => card.id !== cardId);
    const form = {
      name: card.name,
      email: card.email,
    };
    await store.dispatch("add_To_confirm", form);
    await store.dispatch("fetchCards_Approved");
    to_confirm();
  } /*  */
};
const deleteCard = async (cardId) => {
  if (confirm("Вы точно хотите удалить пользователя из системы?")) {
    try {
      cards.value = cards.value.filter((card) => card.id !== cardId);
      await store.dispatch("deleteCard", cardId);
      store.commit("remove_User", cardId);
    } catch {
      alert("Отмена удаления");
    }
  }
};

// ЭТО ДЛЯ ОТКРЫТИЯ календаря на мобилке/планшете
const mobile_calendar = ref(true);
onMounted(() => {
  let windowInnerWidth = window.innerWidth

  if (windowInnerWidth > 1024) {
    mobile_calendar.value = true
  } else {
    mobile_calendar.value = false
  }

  window.addEventListener('resize', () => {
    let windowInnerWidth = window.innerWidth

    if (windowInnerWidth > 1024) {
      mobile_calendar.value = true
    } else {
      mobile_calendar.value = false
    }
  })
});
// ЭТО ДЛЯ ОТКРЫТИЯ календаря на мобилке/планшете КОНЕЦ

//ОТКРЫТИЕ ФИЛЬТРОВ
const menu_popup = ref(false);
//ОТКРЫТИЕ ПОИСКА
const search_open = ref(false);
//ОТКРЫТИЕ ЗАДАЧИ
const open_task = ref(false);

/* Сортируем пользователей от А до Я ; по дате (по id) */
const send_card_sorted = ref();
const usersWasSortedById = ref(false);
const sortSendCardByName = () => {
  send_card_sorted.value = cards.value;
  send_card_sorted.value = [...send_card_sorted.value].sort((a, b) => a.name.localeCompare(b.name));
  cards.value = send_card_sorted.value;
};
const sortSendCardById = () => {
  send_card_sorted.value = cards.value;
  if (!usersWasSortedById.value) {
    send_card_sorted.value = [...send_card_sorted.value].sort((a, b) => b.id - a.id);
    usersWasSortedById.value = true;
  }
  else {
    send_card_sorted.value = [...send_card_sorted.value].sort((a, b) => a.id - b.id);
    usersWasSortedById.value = false;
  }
  cards.value = send_card_sorted.value;
};

//ДИНАМИКА КАЛЕНДАРЯ
const currentYear = ref(new Date().getFullYear());
const currentMonth = ref(new Date().getMonth() + 1); // 1 - январь, 12 - декабрь

const getDaysInMonth = (year, month) => {
  const daysInMonth = new Date(year, month, 0).getDate(); // Количество дней в месяце
  const firstDayOfMonth = new Date(year, month - 1, 1).getDay(); // День недели первого числа (0 - воскресенье, 1 - понедельник и т.д.)
  const shift = (firstDayOfMonth === 0) ? 6 : firstDayOfMonth - 1;
  
  const calendarDays = Array(shift).fill(null); // Заполняем пустыми значениями до первого дня месяца
  for (let day = 1; day <= daysInMonth; day++) {
    calendarDays.push(day); // Добавляем дни месяца
  }
  return calendarDays;
};

const calendarDays = computed(() => getDaysInMonth(currentYear.value, currentMonth.value));

// Метод для перехода на следующий месяц
const next_month = () => {
  if (currentMonth.value === 12) { // Декабрь
    currentMonth.value = 1; // Январь
    currentYear.value += 1; // Увеличиваем год
  } else {
    currentMonth.value += 1; // Увеличиваем месяц
  }
  // updateWeek(1);
};

// Метод для перехода на предыдущий месяц
const previous_month = () => {
  if (currentMonth.value === 1) { // Январь
    currentMonth.value = 12; // Декабрь
    currentYear.value -= 1; // Уменьшаем год
  } else {
    currentMonth.value -= 1; // Уменьшаем месяц
  }
  // updateWeek(1);
};

const today = new Date();
const current_year = ref(today.getFullYear()); // год установленный
const year = current_year.value; // Текущий год

  // Получаем день недели  и месяц

const monthsOfYear = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
  'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];

  const currentMonthName = computed(() => monthsOfYear[currentMonth.value - 1]);  

// Форматируем дату в виде ДД.ММ.ГГГГ

const month = String(today.getMonth() + 1).padStart(2, '0'); // ММ (месяцы начинаются с 0)
const day = today.getDate(); // Текущий день

// Форматируем дату в виде ДД месяца ГГГГ
const monthsOfYearText = ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня',
  'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря'];
const month_name_text = monthsOfYearText[today.getMonth()]; // Название месяца
const formatted_month_Date = `${day} ${month_name_text} ${year}`;


// ПЫТАЮСЬ ПИСАТЬ ПАГИНАЦИЮ
async function paggination() {
  query.value = "";
  notFoundUsers.value = false;
  if (event.target.className.indexOf("toStart") !== -1) {
    // СТИЛИ МЕНЯЕМ
    document.querySelector('.btn-toStart').classList.add("choosen");
    document.querySelector('.btn-toFinish').classList.remove("choosen");

    window.scrollTo(0, 0);
    await store.dispatch("fetchCards", store.state.paggination_links.first);
    await store.dispatch("fetchPaggination", store.state.paggination_links.first);
  } else if (event.target.className.indexOf("Prev") !== -1) {
    if (store.state.paggination_links.prev !== null) {
      window.scrollTo(0, 0);
      await store.dispatch("fetchCards", store.state.paggination_links.prev);
      await store.dispatch("fetchPaggination", store.state.paggination_links.prev);

      // СТИЛИ МЕНЯЕМ
      if (store.state.paggination_links.prev == null) {
        document.querySelector('.btn-toStart').classList.add("choosen")
      } else {
        document.querySelector('.btn-toStart').classList.remove("choosen");
        document.querySelector('.btn-toFinish').classList.remove("choosen");
      }
    }
  } else if (event.target.className.indexOf("Next") !== -1) {
    if (store.state.paggination_links.next !== null) {
      window.scrollTo(0, 0);
      await store.dispatch("fetchCards", store.state.paggination_links.next);
      await store.dispatch("fetchPaggination", store.state.paggination_links.next);

      // СТИЛИ МЕНЯЕМ
      if (store.state.paggination_links.next == null) {
        document.querySelector('.btn-toFinish').classList.add("choosen");
      } else {
        document.querySelector('.btn-toStart').classList.remove("choosen");
        document.querySelector('.btn-toFinish').classList.remove("choosen");
      }
    }
  } else if (event.target.className.indexOf("toFinish") !== -1) {
    // СТИЛИ МЕНЯЕМ
    document.querySelector('.btn-toFinish').classList.add("choosen");
    document.querySelector('.btn-toStart').classList.remove("choosen");

    window.scrollTo(0, 0);
    await store.dispatch("fetchCards", store.state.paggination_links.last);
    await store.dispatch("fetchPaggination", store.state.paggination_links.last).then()
  }
}
</script>

<style scoped>
.dinamic_border {
  border-radius: 32px;
  background-color: rgb(5, 107, 240) !important;
  color: black;
}

.head_icons {
  display: flex;
}

.close-img {
  display: none;
}

@media (max-width: 1024px) {
  .newheader__right__empl {
    display: none;
  }

  .newheader__left {
    position: fixed;
    background-image: url('../assets/img/m-tasks/mobile-calendar-bg.svg');
    background-size: cover;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    max-height: 100vh;
    overflow: scroll;
  }

  .close-img {
    display: block;
  }

  .newheader__left__bottom {
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    background: none;
    margin: 0px !important;
    padding-right: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10vh;
  }

  .newheader__left__bottom__calendar {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
  }

  .newheader__left__bottom__track {
    margin-left: 0px;
    margin-right: 0px;
  }

  .newheader__left__text {
    display: none;
  }

  .newheader__left__token {
    display: flex;
    margin-left: 0px;
    margin-right: 0px;
  }

  .newheader__left__token__gen {
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    border-radius: 12px;
    background-color: rgb(235, 242, 255);
    border: 1px solid transparent;
    width: 100%;
    max-width: 290px;
    font-weight: 600;
    font-size: 16px;
  }

  .newheader__left__token__block {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 11.7px;
    width: 100%;
    max-width: 290px;
  }

  .newheader__left__token__block input {
    width: 290px;
    max-width: 100%;
    border-radius: 12px;
    height: 56px;
    border: 0 transparent;
    font-size: 14px;
    text-align: center;
  }

  .newheader__left__token__padding {
    padding-top: 27px;
    margin-left: 0px;
  }
}

@media (max-width: 375px) {
  .newheader__left__token__padding {
    padding-top: 20px;
  }
}

/* Спасаем карточки */
/* @media (max-width: 490px) {
  .newheader__right__content__block {
    display: none;
  }
} */
.error-message {
  color: var(--main-blue-color);
  font-weight: 500;
  padding-top: 2em;
}

.tasks-page {
  margin-bottom: 0px;
}

@media (max-width: 1024px) {
  .paggination-position {
    display: flex;
    flex-direction: column-reverse;
  }
}

.window_notification {
  position: absolute;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(146, 217, 250);
  border-radius: 12px;
  width: 264px;
  height: 76px;
  z-index: 100;
  right: 90px;
  top: 35px;
}

@media (max-width: 1024px) {
  .window_notification {
    top: 109px;
    right: 40px;
    height: 74px;
  }
}

@media (max-width: 490px) {
  .window_notification {
    top: 83px;
    right: 35px;
    height: 66px;
    width: 250px;
  }
}

</style>
