<template>
  <div class="newheader">
    <!-- УВЕДОМЛЕНИЯ -->
    <div v-show="toggleNotifications" class="window_notification">
      <img @click="toggleNotifications = false" src="@/assets/img/left_close.svg"
        class="close__icon-popup_notification" />
      <VKAllowMessages />
    </div>
    <div class="newheader__left" v-show="mobile_calendar">
      <MBlockLeftLogoTitle />

      <div v-show="mobile_calendar" class="close-img">
        <img @click="mobile_calendar = !mobile_calendar" src="@/assets/img/m-tasks/close.svg" class="close__icon" />
      </div>

      <div class="newheader__left__bottom newheader__left__bottom__padding">
        <div class="newheader__left__bottom__calendar newheader__left__bottom__calendar__padding newheader__container">
          <div class="newheader__left__bottom__calendar_top newheader__left__bottom__calendar_top__padding">
            <div class="newheader__left__month">
              <span> {{ currentMonthName }} {{ currentYear }} </span>
            </div>
            <div class="newheader__left__flex">
              <img @click="previous_month" class="newheader__left__arrow" src="@/assets/img/left_arrow.svg"
                alt="left_arrow" />
              <img @click="next_month" class="newheader__left__arrow" src="@/assets/img/right_arrow.svg"
                alt="right_arrow" />
            </div>
          </div>
          <div class="newheader__left__calendar closed-when-mobile">
            <div class="newheader__left__day__names">П</div>
            <div class="newheader__left__day__names">В</div>
            <div class="newheader__left__day__names">С</div>
            <div class="newheader__left__day__names">Ч</div>
            <div class="newheader__left__day__names">П</div>
            <div class="newheader__left__day__names">С</div>
            <div class="newheader__left__day__names">В</div>
            <div v-for="(item, index) in calendarDays" :key="index" :class="{
                'today': item === day && currentMonth == month && currentYear === year,
                  'selected-day-calendar' : dayOfSelectedDay === item
              }"
               @click="updateWeek(item), updateSelectedDateInCalendar(item)">
              <span v-if="item">{{ item }}</span>
            </div>
          </div>
          <div class="newheader__left__calendar close_grid_desktop">
            <div class="newheader__left__day__names">П</div>
            <div class="newheader__left__day__names">В</div>
            <div class="newheader__left__day__names">С</div>
            <div class="newheader__left__day__names">Ч</div>
            <div class="newheader__left__day__names">П</div>
            <div class="newheader__left__day__names">С</div>
            <div class="newheader__left__day__names">В</div>
            <div v-for="(item, index) in calendarDays" :key="index"
              :class="{ 'today': item === day && currentMonth == month && currentYear === year,
                'selected-day-calendar' : dayOfSelectedDay === item
              }"
              @click="handleDateClick(item), updateSelectedDateInCalendar(item)">
              <span v-if="item">{{ item }}</span>
            </div>
          </div>
        </div>

        <MBlockLeftMenuLinks />

        <MBlockLeftSignupLink :get_reference="get_reference" v-model:random_token="random_token" :link="link" />
        <div class="distance"></div>
        <FooterLeft />
      </div>
    </div>
    <!-- Левый блок до копирования бургер меню для 375px КОНЕЦ -->

    <div class="newheader__right">
      <!-- Новый блок меню от Дениса НАЧАЛО -->
      <header class="mobile__header">
        <section class="head">
          <img @click="mobile_calendar = !mobile_calendar" src="../assets/img/m-tasks/burger_menu.svg"
            class="burger_menu" />
          <div class="head__logo-container">
            <img @click="stop" src="../assets/img/m-tasks/mobile-cloud.svg" class="head__logo" />
            <p class="head__logo-text">Облако задач</p>
          </div>
          <img @click="logout" src="../assets/img/m-tasks/mobile-logout.svg" class="mob_logout" />
        </section>

        <section class="head-panel__container">
          <div class="head-panel__up-content">
            <div class="head-panel__text">
              <h4 class="head-panel__up-title">Сотрудники</h4>
              <p class="head-panel__date">{{ formatted_month_Date }}</p>
            </div>
            <img @click="toggleNotifications = !toggleNotifications" src="@/assets/img/m-tasks/bell.svg"
              class="head__img bell__img" />
          </div>
          <div class="head-panel__down-content">
            <h4 class="head-panel__down-title">Сегодня</h4>
            <div class="head__img-container">
              <img class="head__img" src="../assets/img/m-tasks/white-search.svg" alt="search_right"
                v-show="!search_open" @click="search_open = !search_open" />
              <div class="head__search__open" v-show="search_open">
                <input class="head__search__open__input" v-model="query" @input="searchInConfirmedUsers" type="text"
                  placeholder="Поиск" />
                <img class="head__img" src="@/assets/img/search_right.svg" alt="search_right"
                  @click="search_open = false" />
              </div>
              <img src="@/assets/img/header__filter640.svg" alt="header__filter" class="head__img filter-img"
                @click="menu_popup = !menu_popup" />
            </div>
          </div>
        </section>
      </header>

      <!-- Начинается блок меню Сотрудники (dmt) -->
      <div class="newheader__right__empl newheader__right__empl__padding closed-when-mobile">
        <div class="newheader__right__top newheader__right__top__padding">
          <div class="newheader__right__top__block newheader__right__top__block_lk">
            <h2 class="newheader__right__top__title">Сотрудники</h2>
            <div class="newheader__right__top__lk">
              <div @click="toggleNotifications = !toggleNotifications"
                class="newheader__right__top__wing newheader__right__top__wing__padding1">
                <img @click="link" src="../assets/img/wing.svg" class="mob_logout" />
              </div>

              <div class="newheader__right__top__exit newheader__right__top__exit__padding">
                <img @click="logout" src="@/assets/img/exit.svg" />
              </div>
            </div>
          </div>
        </div>
        <div class="newheader__right__bottom">
          <div class="buttons_confirm">
            <div class="newheader__right__bottom__block1 newheader__right__bottom__block__padding closed-when-mobile">
              <div class="button">
                <div class="newheader__right__bottom__block__flex">
                  <div class="newheader__right__bottom__block__flex newheader__right__bottom__block__fon1">
                    <button @click="to_confirm"
                      class="newheader__right__top__btn_on newheader__right__top__btn_on__color newheader__right__top__btn_on__padding">
                      <span>Пользователи в системе</span>
                      <img class="newheader__right__image" src="@/assets/img/check_right__on.svg" alt="check_right" />
                    </button>
                  </div>
                </div>
              </div>
              <div class="newheader__right__bottom__block__flex ">
                <button @click="to_calendar" class="newheader__right__top__btn">
                  <span>На подтверждение</span>
                  <img class="newheader__right__image" src="@/assets/img/right__plus_on.svg" alt="right__plus_on" />
                </button>
              </div>
            </div>
          </div>
          <div class="newheader__right__bottom__right newheader__right__bottom__right__padding1">
            <div class="newheader__search__confirm">
              <input v-model="query" @input="searchInConfirmedUsers" type="text" placeholder="Найти сотрудника" />
              <img class="newheader__right__search" src="@/assets/img/search_right.svg" alt="search_right" />
            </div>
            <div class="newheader__filter newheader__filter__padding newheader__filter__margin">
              <img src="@/assets/img/header__filter.svg" alt="newheader__filter" @click="menu_popup = !menu_popup" />
            </div>
          </div>
        </div>
      </div>
      <!-- Заканчивается блок меню Сотрудники (dmt) -->
      <section class="menu" v-show="menu_popup" @click="menu_popup = !menu_popup">
        <div class="menu__popup">
          <p class="menu__title">Сортировать</p>
          <button class="menu__btn" @click="sortSendCardByName()">От А до Я</button>
          <button class="menu__btn" @click="sortSendCardById()">По дате</button>
        </div>
      </section>
      <!-- Начинаются кнопки "На подтверждение", "В системе" -->
      <div
        class="newheader__right__bottom__block newheader__right__bottom__block__padding closed-when-desktop opened-when-mobile">
        <div class="newheader__right__bottom__block__flex">
          <button @click="to_confirm" class="newheader__right__top__btn newheader__right__top__btn__color">
            <span>Пользователи в системе</span>
            <img src="@/assets/img/check_right.svg" alt="check_right" class="closed-when-mobile" />
          </button>
        </div>
        <div class="newheader__right__bottom__block__flex">
          <button @click="to_calendar" class="newheader__right__top__btn">
            <span>На подтверждение</span>
            <img src="@/assets/img/right__plus.svg" alt="right__plus" class="closed-when-mobile" />
          </button>
        </div>
      </div>
      <!-- ПАГИНАЦИЯ (в контейнер все обернутом, чтобы на мобилке свапать порядок элементов) -->
      <section class="paggination-position">
        <div class="tasks-page">
          <button class="tasks-page__number btn-toStart choosen" @click="paggination">
            {{ doubleLeftArrow }}
          </button>
          <button class="tasks-page__number btn-Prev" @click="paggination">
            {{ leftArrow }}
          </button>
          <button class="tasks-page__number btn-Next" @click="paggination">
            {{ rightArrow }}
          </button>
          <button class="tasks-page__number btn-toFinish" @click="paggination">
            {{ doubleRightArrow }}
          </button>
        </div>
        <!-- e -->
        <div v-if="notFoundUsers" class="error-message">Нет таких сотрудников...</div>
        <div class="newheader__right__content" v-if="!notFoundUsers">
          <div class="newheader__right__content__block newheader__right__content__block__padding">
            <div v-for="card in query.length >= 3 ? searchResults : (send_card_sorted || send_card)" :key="card.id"
              class="newheader__right__content__card newheader__right__content__card__padding">
              <div class="newheader__right__content__card__flex">
                <div class="newheader__right__content__empl">
                  <span class="newheader__right__content__title">{{
                    card.name
                  }}</span>
                  <span class="newheader__right__content__text">{{
                    card.position
                  }}</span>
                </div>
                <div class="newheader__right__content__btn newheader__right__content__btn__padding">
                  <button @click="delete_From_confirm(card.id)" class="newheader__right__content__btn__del">
                    Удалить
                  </button>
                  <button class="newheader__right__content__btn__add" @click="open_Form_Update_Confirmed_Users(card)">
                    Изменить
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>

  <!-- ОКНО ИЗМЕНЕНИЯ ДАННЫХ ПОЛЬЗОВАТЕЛЯ -->
  <section class="change-user__popup" v-show="change_user" @click="changeUserClose()">
    <!-- ЗАГОЛОВОК -->
    <div class="change-user__content" @click.stop>
      <div class="change-user__head">
        <h2 class="change-user__head__title">Изменения данных</h2>
        <img class="change-user__head__close-icon" src="@/assets/img/m-tasks-user/close-popup-icon.svg"
          @click="changeUserClose()" />
      </div>

      <form class="change_userScroll">
        <!-- ПОЛЯ -->
        <div class="change-user__content__input__container">
          <p class="change-user__content__input__title">Email</p>
          <input class="change-user__content__input" type="email" name="email" placeholder="Введите email"
            v-model="workerEmail">
        </div>
        <div class="change-user__content__input__container">
          <p class="change-user__content__input__title">Пароль</p>
          <input class="change-user__content__input" type="password" name="password" placeholder="Введите пароль"
            v-model="workerPswd">
        </div>
        <div class="change-user__content__input__container">
          <p class="change-user__content__input__title">Повторите пароль</p>
          <input class="change-user__content__input" type="password" name="check_password" placeholder="Введите пароль"
            v-model="workerPswdAgain">
        </div>
        <div class="change-user__content__input__container">
          <p class="change-user__content__input__title">Ф.И.О.</p>
          <input class="change-user__content__input" type="text" name="full_name" placeholder="Введите Ф.И.О."
            v-model="workerName">
        </div>
        <div class="change-user__content__input__container">
          <p class="change-user__content__input__title">Должность</p>
          <input class="change-user__content__input" type="text" name="post" placeholder="Введите должность"
            v-model="workerPosition">
        </div>
        <!-- ПОДТВЕРЖДЕНИЕ -->
        <button type="button" class="change-user__content__btn"
          @click="close_Form_Update_Confirmed_Users(), changeUserClose()">Изменить</button>
      </form>
    </div>
  </section>
</template>

<script setup>
import FooterLeft from './footer-left.vue';
import MBlockLeftLogoTitle from './block__left_logo-title.vue';
import MBlockLeftMenuLinks from './block__left_menu-links.vue';
import MBlockLeftSignupLink from './block__left_signup-link.vue';

import { useRouter } from "vue-router";
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import VKAllowMessages from './m-VKAllowMessages.vue';

const toggleNotifications = ref(false);

const router = useRouter();
const store = useStore();
const random_token = ref("");
const link = computed(() => store.getters.getLink);
const query = ref("");
const token = computed(() => store.getters.getToken);

// Стрелки для пагинации
const doubleLeftArrow = '≪';
const doubleRightArrow = '≫';
const leftArrow = '<';
const rightArrow = '>';

const searchResults = computed(() => store.getters.getSearch || []);
const send_card = computed(() => store.getters.getSendCard);
const notFoundUsers = ref(false);
const change_user = ref(false); //Открытие окна изменения пользователя

onMounted(async () => {
  await store.dispatch("fetchCards_Approved");
  await store.dispatch("fetchPaggination_Approved");
  updateWeek(todayDays)
});

const to_confirm = () => {
  router.push({ name: "MConfirm" });
};

const to_calendar = () => {
  router.push({ name: "MCalendar" });
};


const searchInConfirmedUsers = async () => {
  notFoundUsers.value = false;
  if (query.value.length >= 3) {
    await store.dispatch("searchApprovedUsers", query.value);
    if (String(searchResults.value[0].name) === 'Not found') {
      searchResults.value[0].name = 'Идёт поиск...';
      notFoundUsers.value = true;
    }
  }
  const token = localStorage.getItem("access_token");
  if (token) {
    store.commit("set_Token", token);
  } else {
    store.dispatch("log_Out");
  }
};
const get_reference = async () => {
  try {
    await store.dispatch("recieveUserToken");
    random_token.value = link.value;
  } catch (error) {
    console.error("Error generating registration link:", error);
  }
};

const logout = async () => {
  if (token) {
    store.dispatch("log_Out");
    router.push({ name: "MLogin" });
  } else {
    console.log(error);
  }
};

const delete_From_confirm = async (cardId) => {
  if (confirm("Вы точно хотите удалить пользователя из системы?")) {
    try {
      send_card.value = send_card.value.filter((card) => card.id !== cardId);
      await store.dispatch("deleteCardfromConfirm", cardId);
      store.commit("remove_User_approved", cardId);
    } catch {
      alert("Отмена удаления");
    }
  }
};

const workerDBEmail = ref('');
const workerDBName = ref('');
const workerDBPosition = ref('');

const workerId = ref('');
const workerEmail = ref('');
const workerPswd = ref('');
const workerPswdAgain = ref('');
const workerName = ref('');
const workerPosition = ref('');

const open_Form_Update_Confirmed_Users = async (card) => {
  try {
    changeUser()
    workerId.value = card.id || '';
    workerEmail.value = card.email || '';
    workerPswd.value = card.password || '';
    workerPswdAgain.value = workerPswd.value;
    workerName.value = card.name || '';
    workerPosition.value = card.position || '';

    workerDBEmail.value = card.email;
    workerDBName.value = card.name;
    workerDBPosition.value = card.position;
  } catch (e) {
    alert(e);
  }
};

const close_Form_Update_Confirmed_Users = async () => {
  try {
    const workerData = {
      id: workerId.value,
      name: (workerName.value !== workerDBName.value) ? workerName.value : '',
      email: (workerEmail.value !== workerDBEmail.value) ? workerEmail.value : '',
      password: workerPswd.value,
      password_confirmation: workerPswdAgain.value,
      position: (workerPosition.value !== workerDBPosition.value) ? workerPosition.value : ''
    };
    const responseData = await store.dispatch('update_Confirmed_User', workerData);
    if (responseData.message === 'update success') {
      alert('Данные успешно обновлены!');
      changeUserClose()
      window.location.reload();
    } else {
      changeUserClose()


    }
  } catch (e) {
    alert('Увы, обновить данные не удалось :( ');
    changeUserClose()

  }
};

// ЭТО ДЛЯ ОТКРЫТИЯ календаря на мобилке/планшете
const mobile_calendar = ref(true);
onMounted(() => {
  let windowInnerWidth = window.innerWidth

  if (windowInnerWidth > 1024) {
    mobile_calendar.value = true
  } else {
    mobile_calendar.value = false
  }

  window.addEventListener('resize', () => {
    let windowInnerWidth = window.innerWidth

    if (windowInnerWidth > 1024) {
      mobile_calendar.value = true
    } else {
      mobile_calendar.value = false
    }
  })
});
// ЭТО ДЛЯ ОТКРЫТИЯ календаря на мобилке/планшете КОНЕЦ

//ОТКРЫТИЕ ФИЛЬТРОВ
const menu_popup = ref(false);
//ОТКРЫТИЕ ПОИСКА
const search_open = ref(false);
//ОТКРЫТИЕ ЗАДАЧИ
const open_task = ref(false);

const changeUser = ()=>{
  change_user.value = true;
  document.body.classList.add('no-scroll');
}
const changeUserClose = ()=>{
  change_user.value = false;
  document.body.classList.remove('no-scroll');

}

/* Сортируем пользователей от А до Я ; по дате (по id) */
const send_card_sorted = ref();
const usersWasSortedById = ref(false);
const sortSendCardByName = () => {
  send_card_sorted.value = send_card.value;
  send_card_sorted.value = [...send_card_sorted.value].sort((a, b) => a.name.localeCompare(b.name));
  send_card.value = send_card_sorted.value;
};
const sortSendCardById = () => {
  send_card_sorted.value = send_card.value;
  if (!usersWasSortedById.value) {
    send_card_sorted.value = [...send_card_sorted.value].sort((a, b) => b.id - a.id);
    usersWasSortedById.value = true;
  }
  else {
    send_card_sorted.value = [...send_card_sorted.value].sort((a, b) => a.id - b.id);
    usersWasSortedById.value = false;
  }
  send_card.value = send_card_sorted.value;
};

//ДИНАМИКА КАЛЕНДАРЯ
const currentYear = ref(new Date().getFullYear());
const currentMonth = ref(new Date().getMonth() + 1); // 1 - январь, 12 - декабрь

const getDaysInMonth = (year, month) => {
  const daysInMonth = new Date(year, month, 0).getDate(); // Количество дней в месяце
  const firstDayOfMonth = new Date(year, month - 1, 1).getDay(); // День недели первого числа (0 - воскресенье, 1 - понедельник и т.д.)
  const shift = (firstDayOfMonth === 0) ? 6 : firstDayOfMonth - 1;

  const calendarDays = Array(shift).fill(null); // Заполняем пустыми значениями до первого дня месяца
  for (let day = 1; day <= daysInMonth; day++) {
    calendarDays.push(day); // Добавляем дни месяца
  }
  return calendarDays;
};

const calendarDays = computed(() => getDaysInMonth(currentYear.value, currentMonth.value));

const updateWeek = (day) => {
  const selectedDate = new Date(currentYear.value, currentMonth.value - 1, day);
  const startOfWeek = new Date(selectedDate);
  startOfWeek.setDate(startOfWeek.getDate() - (startOfWeek.getDay() || 7) + 1); // Понедельник
  
  weekDates.value = [];
  for (let i = 0; i < 7; i++) {
    const date = new Date(startOfWeek);
    date.setDate(startOfWeek.getDate() + i);
    
    // Добавляем полную дату в weekDates (день, месяц, год)
    weekDates.value.push({
      day: date.getDate(),
      month: date.getMonth() + 1, // месяцы считаются с 0
      year: date.getFullYear(),
    });
  }
}
// Метод для перехода на следующий месяц
const next_month = () => {
  if (currentMonth.value === 12) { // Декабрь
    currentMonth.value = 1; // Январь
    currentYear.value += 1; // Увеличиваем год
  } else {
    currentMonth.value += 1; // Увеличиваем месяц
  }
  dayOfSelectedDay.value = ''
  updateWeek(1);
};

// Метод для перехода на предыдущий месяц
const previous_month = () => {
  if (currentMonth.value === 1) { // Январь
    currentMonth.value = 12; // Декабрь
    currentYear.value -= 1; // Уменьшаем год
  } else {
    currentMonth.value -= 1; // Уменьшаем месяц
  }
  dayOfSelectedDay.value = ''
  updateWeek(1);
};

const today = new Date();
const todayDays = today.getDate();
const current_year = ref(today.getFullYear()); // год установленный
const weekDates = ref([]);
const year = current_year.value; // Текущий год

// Получаем день недели  и месяц

const monthsOfYear = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
  'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];

const currentMonthName = computed(() => monthsOfYear[currentMonth.value - 1]);

// Форматируем дату в виде ДД.ММ.ГГГГ

const month = String(today.getMonth() + 1).padStart(2, '0'); // ММ (месяцы начинаются с 0)
const day = today.getDate(); // Текущий день
const formattedDate = `${day}.${month}.${year}`;

// Форматируем дату в виде ДД месяца ГГГГ
const monthsOfYearText = ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня',
  'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря'];
const month_name_text = monthsOfYearText[today.getMonth()]; // Название месяца
const formatted_month_Date = `${day} ${month_name_text} ${year}`;


// ПЫТАЮСЬ ПИСАТЬ ПАГИНАЦИЮ
async function paggination() {
  query.value = "";
  notFoundUsers.value = false;
  if (event.target.className.indexOf("toStart") !== -1) {
    // СТИЛИ МЕНЯЕМ
    document.querySelector('.btn-toStart').classList.add("choosen");
    document.querySelector('.btn-toFinish').classList.remove("choosen");

    window.scrollTo(0, 0);
    await store.dispatch("fetchCards_Approved", store.state.paggination_links.first);
    await store.dispatch("fetchPaggination_Approved", store.state.paggination_links.first);
  } else if (event.target.className.indexOf("Prev") !== -1) {
    if (store.state.paggination_links.prev !== null) {
      window.scrollTo(0, 0);
      await store.dispatch("fetchCards_Approved", store.state.paggination_links.prev);
      await store.dispatch("fetchPaggination_Approved", store.state.paggination_links.prev);

      // СТИЛИ МЕНЯЕМ
      if (store.state.paggination_links.prev == null) {
        document.querySelector('.btn-toStart').classList.add("choosen")
      } else {
        document.querySelector('.btn-toStart').classList.remove("choosen");
        document.querySelector('.btn-toFinish').classList.remove("choosen");
      }
    }
  } else if (event.target.className.indexOf("Next") !== -1) {
    if (store.state.paggination_links.next !== null) {
      window.scrollTo(0, 0);
      await store.dispatch("fetchCards_Approved", store.state.paggination_links.next);
      await store.dispatch("fetchPaggination_Approved", store.state.paggination_links.next);

      // СТИЛИ МЕНЯЕМ
      if (store.state.paggination_links.next == null) {
        document.querySelector('.btn-toFinish').classList.add("choosen");
      } else {
        document.querySelector('.btn-toStart').classList.remove("choosen");
        document.querySelector('.btn-toFinish').classList.remove("choosen");
      }
    }
  } else if (event.target.className.indexOf("toFinish") !== -1) {
    // СТИЛИ МЕНЯЕМ
    document.querySelector('.btn-toFinish').classList.add("choosen");
    document.querySelector('.btn-toStart').classList.remove("choosen");

    window.scrollTo(0, 0);
    await store.dispatch("fetchCards_Approved", store.state.paggination_links.last);
    await store.dispatch("fetchPaggination_Approved", store.state.paggination_links.last)
  }
}
function formatDate(dateString) {
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('ru-RU', options);
}
function resetTime(date) {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0); // Обнуляем часы, минуты, секунды и миллисекунды
  return newDate;
}
const handleDateClick = (item) => {
  mobile_calendar.value = false; // Закрыть попап
  updateWeek(item); // Обновить неделю
};
  //обновление даты
  const todayTasks = ref()
  const noTasks = ref(false)
  const formattedToday = `${year}-${month}-${day}`;
  const selectedDay = ref(formattedToday); // По умолчанию сегодня
  const dayOfSelectedDay = ref('')
  const selectedDateWeek = ref(formatted_month_Date); // По умолчанию текущая дата в формате "ДД месяц ГГГГ"
  const updateSelectedDateInCalendar = (dayIndex) => {
  const filteredCalendarDays = calendarDays.value.filter(item => item != null);
  const day = filteredCalendarDays[dayIndex - 1];
    noTasks.value = false
  const selectedDateObj = weekDates.value.find(dateObj => dateObj.day === day);
  
  if (selectedDateObj) {
    selectedDay.value = `${selectedDateObj.year}-${String(selectedDateObj.month).padStart(2, '0')}-${String(selectedDateObj.day).padStart(2, '0')}`;
    selectedDateWeek.value = formatDate(selectedDay.value);
    dayOfSelectedDay.value = selectedDateObj.day;
};
  }
</script>

<style scoped>
.dinamic_border {
  border-radius: 32px;
  background-color: rgb(5, 107, 240) !important;
  color: black;
}

/* .newheader__close {
  display: none;
} */

.close-img {
  display: none;
}

@media (max-width: 1024px) {
  .newheader__right__empl {
    display: none;
  }

  .newheader__left {
    position: fixed;
    background-image: url('../assets/img/m-tasks/mobile-calendar-bg.svg');
    background-size: cover;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    max-height: 100vh;
    overflow: scroll;
  }

  .close-img {
    display: block;
  }

  .newheader__left__bottom {
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    background: none;
    margin: 0px !important;
    padding-right: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10vh;
  }

  .newheader__left__bottom__calendar {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
  }

  .newheader__left__bottom__track {
    margin-left: 0px;
    margin-right: 0px;
  }

  .newheader__left__text {
    display: none;
  }

  .newheader__left__token {
    display: flex;
    margin-left: 0px;
    margin-right: 0px;
  }

  .newheader__left__token__gen {
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    border-radius: 12px;
    background-color: rgb(235, 242, 255);
    border: 1px solid transparent;
    width: 100%;
    max-width: 290px;
    font-weight: 600;
    font-size: 16px;
  }

  .newheader__left__token__block {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 11.7px;
    width: 100%;
    max-width: 290px;
  }

  .newheader__left__token__block input {
    width: 290px;
    max-width: 100%;
    border-radius: 12px;
    height: 71px;
    border: 0 transparent;
    font-size: 14px;
    text-align: center;
  }
  .newheader__left__token__padding {
    padding-top: 27px;
    margin-left: 0px;
  }
}

@media (max-width: 375px) {
  .newheader__left__token__padding {
    padding-top: 20px;
  }
}

/* Спасаем карточки */
/* @media (max-width: 490px) {
  .newheader__right__content__block {
    display: none;
  }
} */

/* Тут непонятно, зачем эти стили нужны (scss и без них всё хорошо) - ElenaFro ? */
/* .mobile__header {
  @include media(1024px) {
    margin-top: 20px;
  }
} */
/* А вот эти стили работают (css), но они не нужны */
/* @media (min-width: 1024px) {
  .mobile__header {
    margin-top: 20px;
  }
} */

.error-message {
  color: var(--main-blue-color);
  font-weight: 500;
  padding-top: 2em;
}

.tasks-page {
  margin-bottom: 0px;
}

@media (max-width: 1024px) {
  .paggination-position {
    display: flex;
    flex-direction: column-reverse;
  }
}

.window_notification {
  position: absolute;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(146, 217, 250);
  border-radius: 12px;
  width: 264px;
  height: 76px;
  z-index: 100;
  right: 90px;
  top: 35px;
}

@media (max-width: 1024px) {
  .window_notification {
    top: 109px;
    right: 40px;
    height: 74px;
  }
}

@media (max-width: 490px) {
  .window_notification {
    top: 83px;
    right: 35px;
    height: 66px;
    width: 250px;
  }
}
.selected-day {
  border: 1px solid rgb(5, 107, 240); /* Обводка для выбранного дня */
}
.selected-day-calendar{
  border-radius: 32px;
  margin: auto;
  border: 1px solid rgb(5, 107, 240); /* Обводка для выбранного дня */
}
@media(min-width: 1375px){
  .selected-day-calendar{
    width: 40px;
    height: 40px;
  }
}
.bg-blue {
  background-color: #056BF0;
  border-radius: 40px;
}

.text-white {
  color: white;
  /* Белый текст */
}

.bg-default {
  background-color: transparent;
  /* Прозрачный фон или другой цвет по умолчанию */
}

.text-default {
  color: #000826;
  /* Цвет текста по умолчанию */
}
</style>
