<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script setup>
import { defineComponent } from "vue";
const App = defineComponent({
  name: 'App'
});
</script>
<style lang="scss">
@import 'assets/css/style.css';
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  font-family: "Manrope", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
