<template>
  <section class="login-new login__body-background-image">
    <div class="login login__container">
      <div class="login__content">
        <div class="login__content__title__padding">
          <img
            src="@/assets/img/m-login/login_logo.svg"
            alt="logo"
            class="login__content__title__padding_img"
          />
        </div>
        <div v-if="isLoggedIn" class="login__content__card login__content__card_for_login login__content__form">
          <button @click="redirectUser">
            {{ `Добро пожаловать, ${ (user_name) === 'admin' ? 'директор' : user_name }!` }}
          </button>
        </div>
        <div v-else class="login__content__card">
          <form @submit.prevent="login_user" method="post">
            <div class="login__content__form login__content__form__padding">
              <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
              <div class="login__content__form__input">
                <input
                  v-model="form.email"
                  type="e-mail"
                  name="username"
                  placeholder="Логин"
                />
                <img
                  class="login__content__form__img"
                  src="@/assets/img/m-login/login_email-icon.svg"
                  alt="login_email"
                />
              </div>
              <div class="login__content__form__input">
                <input
                  v-model="form.password"
                  type="password"
                  name="password"
                  placeholder="Пароль"
                />
                <img
                  class="login__content__form__img"
                  src="@/assets/img/m-login/login_password-icon.svg"
                  alt="login__password"
                />
              </div>
              <button type="submit">Войти в систему</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- <div class="link">
      <router-link :to="{ name: 'MCalendar' }">To Calendar</router-link>
      <router-link :to="{ name: 'MRegistr' }">To Registration</router-link>
    </div> -->
    <Footer />
  </section>
  <router-view />
</template>

<script setup>
import axios from "axios";
import { useStore } from "vuex";
import { reactive, computed, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import apiClient from "../axios/axios.js";
import Footer from './footer.vue';

const store = useStore();
const router = useRouter();
const token = computed(() => store.getters.getToken);
const role = computed(() => store.getters.getRole);
const user_name = computed(() => store.getters.getUserName);
const form = reactive({
  email: "",
  password: "",
});

const errorMessage = ref("");

const isLoggedIn = ref(false);
onMounted(() => {
  if (token.value && user_name.value) {
    isLoggedIn.value = true;
  }
});

const login_user = async () => {
  try {
    await store.dispatch("fetchUserData", form);
    if (token.value) {
      alert(`Добро пожаловать, ${ (user_name.value) === 'admin' ? 'директор' : user_name.value }!`);
      redirectUser();
    } else {
      errorMessage.value = "Неверный логин или пароль";
    }
  } catch (error) {
    console.log(error);
  }
};

const redirectUser = () => {
  router.push({ name: role.value === "admin" ? "MAssignTask" : "MAssignTaskUser" });
};
</script>

<style scoped>
.error-message {
    color: var(--main-blue-color);
    font-weight: 500;
}
.login__content__card_for_login {
  padding: 0.4em;
}
.login__content__form button {
  margin: 0;
  padding: 1em;
}
</style>