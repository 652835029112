import { createRouter, createWebHistory } from "vue-router";
import store from '../store/index';

import MCalendar from "../components/m-calendar.vue";
import MLogin from "../components/m-login.vue";
import MRegistr from "../components/m-registr.vue";
import MConfirm from "../components/m-calendar_confirm.vue";
import MNotFoundPage from "../components/m-404.vue";
import MCompleted from "../components/m-completed_tasks.vue";
import MAssignTask from "../components/m-assign_task.vue";
import MAssignTaskUser from "../components/m-assign_task-user.vue";
import MCompletedUser from "../components/m-completed_tasks-user.vue";
import MUnfulfield from "../components/m-unfulfield_tasks.vue";
import MUnfulfieldUser from "../components/m-unfulfield_tasks-user.vue";
import MTest from "../components/m-test.vue";

const routes = [

  // Страницы входа-регистрации
  {
    path: "/",
    name: "MLogin",
    component: MLogin,
    props: true,
  },
  {
    path: "/MRegistr",
    name: "MRegistr",
    component: MRegistr,
    props: true,
  },
  // Админские страницы (5 страниц)
  {
    path: "/MAssignTask",
    name: "MAssignTask",
    component: MAssignTask,
    props: true,
  },
  {
    path: "/MCompleted",
    name: "MCompleted",
    component: MCompleted,
    props: true,
  },
  {
    path: "/MUnfulfield",
    name: "MUnfulfield",
    component: MUnfulfield,
    props: true,
  },
  {
    path: "/MCalendar/MConfirm",
    name: "MConfirm",
    component: MConfirm,
    props: true,
  },
  {
    path: "/MCalendar",
    name: "MCalendar",
    component: MCalendar,
    props: true,
  },
  // Пользовательские страницы (3 страницы)
  {
    path: "/MAssignTaskUser",
    name: "MAssignTaskUser",
    component: MAssignTaskUser,
    props: true,
  },
  {
    path: "/MCompletedUser",
    name: "MCompletedUser",
    component: MCompletedUser,
    props: true,
  },
  {
    path: "/MUnfulfieldUser",
    name: "MUnfulfieldUser",
    component: MUnfulfieldUser,
    props: true,
  },
  /* ТЕСТ НАЧАТ */
  {
    path: "/MTest",
    name: "MTest",
    component: MTest,
    props: true,
  },
  /* ТЕСТ ЗАКОНЧЕН */
  // Все остальные "ошибочные" страницы
  {
    path: "/:pathMatch(.*)*",
    name: "MNotFoundPage",
    component: MNotFoundPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = !!store.getters.getToken;
  const currentRole = store.getters.getRole;
  if (!isAuthenticated) {
    if (['MAssignTask', 'MCompleted', 'MUnfulfield', 'MConfirm', 'MCalendar', 'MAssignTaskUser', 'MCompletedUser', 'MUnfulfieldUser'].includes(to.name)) {
      next({ name: 'MNotFoundPage' });
      return;
    }
    next();
    return;
  } else if (currentRole === 'admin') {
    if (['MAssignTask', 'MCompleted', 'MUnfulfield', 'MConfirm', 'MCalendar'].includes(to.name)) {
      next();
      return;
    } else if (['MAssignTaskUser', 'MCompletedUser', 'MUnfulfieldUser', 'MRegistr'].includes(to.name)) {
      next({ name: 'MNotFoundPage' });
      return;
    }
  } else if (currentRole !== 'admin') {
    if (['MAssignTaskUser', 'MCompletedUser', 'MUnfulfieldUser'].includes(to.name)) {
      next();
      return;
    } else if (['MAssignTask', 'MCompleted', 'MUnfulfield', 'MConfirm', 'MCalendar', 'MRegistr'].includes(to.name)) {
      next({ name: 'MNotFoundPage' });
      return;
    }
  }
  next();
});

export default router;
