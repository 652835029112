<template>
  <router-link :to="computedRoute" class="custom-link">
  <div class="newheader__left__text newheader__container newheader__left__padding newheader__burger__logotype">
    <img src="@/assets/img/header__task.svg" alt="header__task" />
    <h2>Облако задач</h2>
  </div>
  </router-link>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'MBlockLeftLogoTitle',
  computed: {
    ...mapState({
      role: state => state.role
    }),
    computedRoute() {
      return this.role === 'admin' ? { name: 'MAssignTask' } : { name: 'MAssignTaskUser' };
    }
  }
};
</script>

<style>
@media (max-width: 1024px) {
  .newheader__burger__logotype {
      display: none;
  }
}
</style>