<template>
  <div class="newheader">
    <!-- УВЕДОМЛЕНИЯ -->
    <div v-show="toggleNotifications" class="window_notification">
      <img @click="toggleNotifications = false" src="@/assets/img/left_close.svg"
        class="close__icon-popup_notification" />
      <VKAllowMessages />
    </div>
    <div class="newheader__left" v-show="mobile_calendar">

      <MBlockLeftLogoTitle />

      <div v-show="mobile_calendar" class="close-img">
        <img @click="mobile_calendar = !mobile_calendar" src="@/assets/img/m-tasks/close.svg" class="close__icon" />
      </div>

      <div class="newheader__left__bottom newheader__left__bottom__padding">
        <div class="newheader__left__bottom__calendar newheader__left__bottom__calendar__padding newheader__container">
          <div class="newheader__left__bottom__calendar_top newheader__left__bottom__calendar_top__padding">
            <div class="newheader__left__month">
              <span> {{ currentMonthName }} {{ currentYear }}</span>
            </div>
            <div class="newheader__left__flex">
              <img @click="previous_month" class="newheader__left__arrow" src="@/assets/img/left_arrow.svg"
                alt="left_arrow" />
              <img @click="next_month" class="newheader__left__arrow" src="@/assets/img/right_arrow.svg"
                alt="right_arrow" />
            </div>
          </div>
          <div class="newheader__left__calendar closed-when-mobile">
            <div class="newheader__left__day__names">П</div>
            <div class="newheader__left__day__names">В</div>
            <div class="newheader__left__day__names">С</div>
            <div class="newheader__left__day__names">Ч</div>
            <div class="newheader__left__day__names">П</div>
            <div class="newheader__left__day__names">С</div>
            <div class="newheader__left__day__names">В</div>
            <div v-for="(item, index) in calendarDays" :key="index" :class="{
                'today': item === day && currentMonth == month && currentYear === year,
                  'selected-day-calendar' : dayOfSelectedDay === item
              }"
               @click="updateWeek(item), updateSelectedDateInCalendar(item)">
              <span v-if="item">{{ item }}</span>
            </div>
          </div>
          <div class="newheader__left__calendar close_grid_desktop">
            <div class="newheader__left__day__names">П</div>
            <div class="newheader__left__day__names">В</div>
            <div class="newheader__left__day__names">С</div>
            <div class="newheader__left__day__names">Ч</div>
            <div class="newheader__left__day__names">П</div>
            <div class="newheader__left__day__names">С</div>
            <div class="newheader__left__day__names">В</div>
            <div v-for="(item, index) in calendarDays" :key="index"
              :class="{ 'today': item === day && currentMonth == month && currentYear === year,
                'selected-day-calendar' : dayOfSelectedDay === item
              }"
              @click="handleDateClick(item), updateSelectedDateInCalendar(item)">
              <span v-if="item">{{ item }}</span>
            </div>
          </div>
        </div>
        <div class="newheader__left__bottom__track newheader__left__bottom__track__padding">
        <router-link :to="{ name: 'MAssignTask' }" class="custom-link">
            <div class="newheader__left__bottom__track__block newheader__left__bottom__no_marker">
                <span>Назначить задачу</span>
                <img src="@/assets/img/left_plus.svg" alt="left_plus" />
            </div>
        </router-link>
        <router-link :to="{ name: 'MCompleted' }" class="custom-link">
            <div @click="reload" class="newheader__left__bottom__track__block newheader__left__bottom__marker">
                <span>Выполненные</span>
                <img src="@/assets/img/left_check.svg" alt="left_check" />
            </div>
        </router-link>
        <router-link :to="{ name: 'MUnfulfield' }" class="custom-link">
            <div class="newheader__left__bottom__track__block newheader__left__bottom__no_marker">
                <span>Невыполненные</span>
                <img src="@/assets/img/left_close.svg" alt="left_close" />
            </div>
        </router-link>
        <router-link :to="{ name: 'MConfirm' }" class="custom-link">
            <div class="newheader__left__bottom__track__block newheader__left__bottom__no_marker">
                <span>Сотрудники</span>
                <img src="@/assets/img/left_people.svg" alt="left_people" />
            </div>
        </router-link>
    </div>

        <MBlockLeftSignupLink :get_reference="get_reference" v-model:random_token="random_token" :link="link" />
        <div class="distance"></div>
        <FooterLeft />
      </div>
    </div>

    <div class="newheader_right">
      <div class="newheader__right__empl1 newheader__right__empl1__margin closed-when-mobile">
        <div class="newheader__right__problems">
          <h2 class="newheader__right__top__title">Выполненные задачи</h2>
          <div class="newheader__right__bottom__right newheader__right__bottom__right__padding1">
            <div class="newheader__search">
              <input v-model="query" @input="searchTask" type="text" placeholder="Поиск задачи" />
              <img class="newheader__right__search" src="@/assets/img/search_right.svg" alt="search_right" />
            </div>
          </div>
          <div class="newheader__accaunt">
            <div @click="toggleNotifications = !toggleNotifications" class="newheader__right__bell">
              <div class="newheader__right__top__wing newheader__right__top__wing__padding1">
                <img src="@/assets/img/wing.svg" />
              </div>
            </div>
            <div class="newheader__right__top__exit newheader__right__top__exit__padding2">
              <img @click="logout" src="@/assets/img/exit.svg" />
            </div>
          </div>
        </div>
      </div>

      <header class="mobile__header" v-show="!mobile_calendar">
        <section class="head">
          <img @click="mobile_calendar = !mobile_calendar" src="../assets/img/m-tasks/burger_menu.svg"
            class="burger_menu" />
          <div class="head__logo-container">
            <img @click="stop" src="../assets/img/m-tasks/mobile-cloud.svg" class="head__logo" />
            <p class="head__logo-text">Облако задач</p>
          </div>
          <img @click="logout" src="../assets/img/m-tasks/mobile-logout.svg" class="mob_logout" />
        </section>

        <section class="head-panel__container">
          <div class="head-panel__up-content">
            <div class="head-panel__text">
              <h4 class="head-panel__up-title">Выполненные задачи</h4>
              <p class="head-panel__date">{{ formatted_month_Date }}</p>
            </div>
            <img @click="toggleNotifications = !toggleNotifications" src="@/assets/img/m-tasks/bell.svg"
              class="head__img bell__img" />
          </div>
          <div class="head-panel__down-content">
            <h4 class="head-panel__down-title">Сегодня</h4>
            <div class="head__img-container">
              <img class="head__img" src="../assets/img/m-tasks/white-search.svg" alt="search_right"
                v-show="!search_open" @click="search_open = !search_open" />
              <div class="head__search__open" v-show="search_open">
                <input class="head__search__open__input" v-model="query" @input="searchTask" type="text"
                  placeholder="Введите что-то для поиска" />
                <img class="head__img" src="@/assets/img/search_right.svg" alt="search_right"
                  @click="search_open = false" />
              </div>
            </div>
          </div>
        </section>
      </header>

      <div class="header_TASKS__right__content">
        <section class="tasks-content">
          <div class="tasks-page">
            <button class="tasks-page__number btn-toStart choosen" @click="paggination">
              {{ doubleLeftArrow }}
            </button>
            <button class="tasks-page__number btn-Prev" @click="paggination">
              {{ leftArrow }}
            </button>
            <button class="tasks-page__number btn-Next" @click="paggination">
              {{ rightArrow }}
            </button>
            <button class="tasks-page__number btn-toFinish" @click="paggination">
              {{ doubleRightArrow }}
            </button>
          </div>

          <div v-show="notFoundTask" class="error-message">Задача не найдена...</div>
          <!-- КАРТОЧКИ -->
          <section class="tasks__container" v-show="!notFoundTask">
            <div v-if="!noTasks" :class="task.is_urgently ? 'task__rush' : 'task'"
              v-for="task in query.length >= 3 ? searchResults : todayTasks" :key="task.id">
              <div>
                <h3 class="task__header title_all">
                  {{ (task.name.indexOf(':::') === -1) ? task.name : task.name.split(':::')[0] }}
                </h3>
                <p class="task__time">
                  с {{
                    task.start_date ? `${task.start_date.slice(0, 10).replace(/-/g, '.')} по ${task.end_date.slice(0,
                      10).replace(/-/g, '.')}` : ""
                  }}
                </p>
                <p class="task__text title_all">
                  {{ task.description }}
                </p>
              </div>
              <div class="task__btn-container">
                <button class="task__btn__delete" @click="deleteTask(task.id)">Удалить</button>
                <button class="task__btn" @click="openTaskDetails(task)">Открыть</button>
              </div>
            </div>
            <div v-else class="error-message"> Задач нет...</div>
          </section>
        </section>

        <section class="task__popup" v-show="open_task" @click="closeTask">
          <div class="task__popup__content" @click.stop>
            <div class="task__popup__header">
              <h4 class="task__popup__header__status">Завершенная задача</h4>
              <img @click="closeTask" src="../assets/img/m-tasks-user/close-popup-icon.svg"
                class="close__icon-popup" />
            </div>

            <div class="task__popup__scroll">
              <!-- ДЕТАЛИ ОПИСАНИЯ -->
              <h4 class="task__popup__title text__margin-bottom">Описание задачи</h4>
              <p class="task__popup__description__text">Автор задачи:
                <span class="task__popup__description__text-white">
                  {{
                  concreteTaskAuthor ? (concreteTaskAuthor === 'admin') ? 'Директор' : concreteTaskAuthor : 'не указан'
                  }}
                </span>
              </p>
              <p class="task__popup__description__text">Исполнитель:
                <span class="task__popup__description__text-white">
                  {{ concreteTaskParticipants ? concreteTaskParticipants : 'не указан' }}
                </span>
              </p>
              <p class="task__popup__description__text">Дата:
                <span class="task__popup__description__text-white">{{ selectedTask.start_date }} - {{
                  selectedTask.end_date }}</span>
              </p>
              <p class="task__popup__description__text">Приоритетность:
                <span class="task__popup__description__text-white">
                  {{ selectedTask.is_urgently ? 'срочная задача' : 'обычная задача' }}
                </span>
              </p>
              <!-- САМА ЗАДАЧА -->
              <h4 class="task__popup__title text__margin-bottom text__margin-top">
                <span class="task__popup__description__text-white">{{ selectedTask.name }}</span>
              </h4>
              <p class="task__popup__text text__margin">
                <span class="task__popup__description__text-white">{{ selectedTask.description }}</span>
              </p>

              <!-- Подзадачи -->
              <ul class="task__popup__list-container">
                <li class="task__popup__list" v-for="(subtask, index) in concreteTaskChecklist" :key="index">
                  <img :src="subtask.is_selected ?
                    require('@/assets/img/m-tasks-user/list-icon.svg') :
                    require('@/assets/img/m-assign-task/white_for_check.avif')" class="list__icon-popup"
                    alt="tick_task" />
                  <!-- Если надо будет сделать возможно снимать/ставить галочки на подзадачи -->
                  <!-- @click="toggleSubtaskTick(index)" -->
                  <p class="task__popup__list__text">{{ subtask.text }}</p>
                </li>
              </ul>

              <!-- Комментарии -->
              <form class="task__popup__form">
                <p class="task__popup__text text__margin-top text__margin-bottom phone-comment">Комментарии к задаче</p>

                <!-- Вывод комментариев и файлов -->
                <div v-for="comment in concreteTaskComments" :key="comment" class="task__popup__comments">

                  <p class="task__popup__text" :style="{ 'user-select': 'none', 'pointer-events': 'none' }">
                    <span class="task__popup__description__text-white">{{ getUserNameByIdForComment(comment.author)
                      }}</span>
                  </p>

                  <textarea v-if="!comment.fileUrl" class="task__popup__input task__popup__text"
                    :placeholder="comment.text !== ' ' ? comment.text : comment.fileName" readonly :style="{
                      'user-select': 'none',
                      'pointer-events': 'auto',
                      'overflow-y': 'auto',
                      'resize': 'none',
                    }" />

                  <a v-else-if="comment.fileUrl" :href="comment.fileUrl" download
                    class="task__popup__input task__popup__text" :style="{
                      'display': 'block',
                      // 'text-decoration': 'none',
                      'color': '#056bf0',
                      'background-color': '#ebf2ff'
                    }" target="_blank" > {{ comment.fileName || comment.text }}
                  </a>

                </div>

              </form>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script setup>
import FooterLeft from './footer-left.vue';
import MBlockLeftLogoTitle from './block__left_logo-title.vue';
import MBlockLeftMenuLinks from './block__left_menu-links.vue';
import MBlockLeftSignupLink from './block__left_signup-link.vue';

import { ref, computed, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import apiClient from "../axios/axios.js";
import { useRouter } from "vue-router";
import VKAllowMessages from './m-VKAllowMessages.vue';

const toggleNotifications = ref(false);

const store = useStore();
const router = useRouter();
const random_token = ref("");
const query = ref("");
const cards = computed(() => store.getters.getCards || []);
const link = computed(() => store.getters.getLink);
const token = computed(() => store.getters.getToken);

const weekDates = ref([]);

//Поиск 
const searchResults = computed(() => store.getters.getSearch || []);
const notFoundTask = ref(false);
const searchTask = async () => {
  notFoundTask.value = false;
  if (query.value.length >= 3) {
    await store.dispatch("searchTask", { query: query.value, type: true });
    if (String(searchResults.value[0].name) === "Not found") {
      searchResults.value[0].name = "Идёт поиск...";
      notFoundTask.value = true;
    }
  }

  if (query.value.length < 3) {
    notFoundTask.value = false;
  }
};


/* Получение выполненных задач */
const selectedTask = ref({});
const tasks = computed(() => store.getters.getShowAdminTasks);

const concreteTaskAuthor = computed(() => store.getters.getConcreteTaskAuthor);
const concreteTaskParticipants = computed(() => store.getters.getConcreteTaskParticipants);
const concreteTaskChecklist = computed(() => store.getters.getConcreteTaskChecklist);
const concreteTaskComments = computed(() => store.getters.getConcreteTaskComments);

const open_task = ref(false);

// Стрелки для пагинации
const doubleLeftArrow = '≪';
const doubleRightArrow = '≫';
const leftArrow = '<';
const rightArrow = '>';

/* Подгружаем список всех пользователей для отображения имен в комментариях к задачам */
const allUsers = computed(() => store.getters.getTaskUser);

/* Получение имени сотрудника (автора комментария к задаче) */
const getUserNameByIdForComment = (userId) => {
  if (userId === 1) return 'Директор';
  const user = allUsers.value.find(user => user.id === userId);
  return user ? user.name : `Сотрудник № ${userId}`;
};

const openTaskDetails = (task) => {
  selectedTask.value = task;
  selectedTask.value.name = selectedTask.value.name.split(':::')[0];
  selectedTask.value.start_date = selectedTask.value.start_date.slice(0, 16).replace(/-/g, '.');
  selectedTask.value.end_date = selectedTask.value.end_date.slice(0, 16).replace(/-/g, '.');
  openTask()
  store.dispatch("showConcreteTaskExtraData", task.id);
};

const deleteTask = async (task) => {
  await store.dispatch("deleteTask", task);
}
const reload = async()=>{
  window.location.reload()
}
onMounted(async () => {
  await store.dispatch("showAllAdminTasks", { isCompleted: true, link: "" });
  await store.dispatch("fetchPaggination_AdminTasks");
  await store.dispatch('showUserTask');
  todayTasks.value = tasks.value
  updateWeek(todayDays)
});

const get_reference = async () => {
  try {
    await store.dispatch("recieveUserToken");
    random_token.value = link.value;
  } catch (error) {
    console.error("Error generating registration link:", error);
  }
};

const to_confirm = () => {
  router.push({ name: "MCompleted" });
};

const logout = async () => {
  if (token) {
    store.dispatch("log_Out");
    router.push({ name: "MLogin" });
  } else {
    console.log(error);
  }
};


// ЭТО ДЛЯ ОТКРЫТИЯ календаря на мобилке/планшете
const mobile_calendar = ref(true);
onMounted(() => {
  let windowInnerWidth = window.innerWidth

  if (windowInnerWidth > 1024) {
    mobile_calendar.value = true
  } else {
    mobile_calendar.value = false
  }

  window.addEventListener('resize', () => {
    let windowInnerWidth = window.innerWidth

    if (windowInnerWidth > 1024) {
      mobile_calendar.value = true
    } else {
      mobile_calendar.value = false
    }
  })
});
const openTask = ()=>{
  open_task.value = true;
  document.body.classList.add('no-scroll')
}
const closeTask = ()=>{
  open_task.value = false;
  document.body.classList.remove('no-scroll')
}

//ОТКРЫТИЕ ПОИСКА
const search_open = ref(false);

//ДИНАМИКА КАЛЕНДАРЯ
const currentYear = ref(new Date().getFullYear());
const currentMonth = ref(new Date().getMonth() + 1); // 1 - январь, 12 - декабрь

const getDaysInMonth = (year, month) => {
  const daysInMonth = new Date(year, month, 0).getDate(); // Количество дней в месяце
  const firstDayOfMonth = new Date(year, month - 1, 1).getDay(); // День недели первого числа (0 - воскресенье, 1 - понедельник и т.д.)
  const shift = (firstDayOfMonth === 0) ? 6 : firstDayOfMonth - 1;

  const calendarDays = Array(shift).fill(null); // Заполняем пустыми значениями до первого дня месяца
  for (let day = 1; day <= daysInMonth; day++) {
    calendarDays.push(day); // Добавляем дни месяца
  }
  return calendarDays;
};

const calendarDays = computed(() => getDaysInMonth(currentYear.value, currentMonth.value));
const updateWeek = (day) => {
  const selectedDate = new Date(currentYear.value, currentMonth.value - 1, day);
  const startOfWeek = new Date(selectedDate);
  startOfWeek.setDate(startOfWeek.getDate() - (startOfWeek.getDay() || 7) + 1); // Понедельник
  
  weekDates.value = [];
  for (let i = 0; i < 7; i++) {
    const date = new Date(startOfWeek);
    date.setDate(startOfWeek.getDate() + i);
    
    // Добавляем полную дату в weekDates (день, месяц, год)
    weekDates.value.push({
      day: date.getDate(),
      month: date.getMonth() + 1, // месяцы считаются с 0
      year: date.getFullYear(),
    });
  }
}
// Метод для перехода на следующий месяц
const next_month = () => {
  if (currentMonth.value === 12) { // Декабрь
    currentMonth.value = 1; // Январь
    currentYear.value += 1; // Увеличиваем год
  } else {
    currentMonth.value += 1; // Увеличиваем месяц
  }
  dayOfSelectedDay.value = ''
  updateWeek(1);
};

// Метод для перехода на предыдущий месяц
const previous_month = () => {
  if (currentMonth.value === 1) { // Январь
    currentMonth.value = 12; // Декабрь
    currentYear.value -= 1; // Уменьшаем год
  } else {
    currentMonth.value -= 1; // Уменьшаем месяц
  }
  dayOfSelectedDay.value = ''
  updateWeek(1);
};

const today = new Date();
const todayDays = today.getDate();
const current_year = ref(today.getFullYear()); // год установленный
const year = current_year.value; // Текущий год

// Получаем день недели  и месяц

const monthsOfYear = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
  'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];

const currentMonthName = computed(() => monthsOfYear[currentMonth.value - 1]);

// Форматируем дату в виде ДД.ММ.ГГГГ

const month = String(today.getMonth() + 1).padStart(2, '0'); // ММ (месяцы начинаются с 0)
const day = today.getDate(); // Текущий день

// Форматируем дату в виде ДД месяца ГГГГ
const monthsOfYearText = ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня',
  'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря'];
const month_name_text = monthsOfYearText[today.getMonth()]; // Название месяца
const formatted_month_Date = `${day} ${month_name_text} ${year}`;

//Пагинация
async function paggination() {
  query.value = "";
  notFoundTask.value = false;
  if (event.target.className.indexOf("toStart") !== -1) {
    // СТИЛИ МЕНЯЕМ
    document.querySelector('.btn-toStart').classList.add("choosen");
    document.querySelector('.btn-toFinish').classList.remove("choosen");

    window.scrollTo(0, 0);
    await store.dispatch("showAllAdminTasks", { isCompleted: true, link: store.state.paggination_links.first_page_url });
    await store.dispatch("fetchPaggination_AdminTasks", store.state.paggination_links.first_page_url);
  } else if (event.target.className.indexOf("Prev") !== -1) {
    if (store.state.paggination_links.prev_page_url !== null) {
      window.scrollTo(0, 0);
      await store.dispatch("showAllAdminTasks", { isCompleted: true, link: store.state.paggination_links.prev_page_url });
      await store.dispatch("fetchPaggination_AdminTasks", store.state.paggination_links.prev_page_url);

      // СТИЛИ МЕНЯЕМ
      if (store.state.paggination_links.prev_page_url == null) {
        document.querySelector('.btn-toStart').classList.add("choosen")
      } else {
        document.querySelector('.btn-toStart').classList.remove("choosen");
        document.querySelector('.btn-toFinish').classList.remove("choosen");
      }
    }
  } else if (event.target.className.indexOf("Next") !== -1) {
    if (store.state.paggination_links.next_page_url !== null) {
      window.scrollTo(0, 0);
      await store.dispatch("showAllAdminTasks", { isCompleted: true, link: store.state.paggination_links.next_page_url });
      await store.dispatch("fetchPaggination_AdminTasks", store.state.paggination_links.next_page_url);

      // СТИЛИ МЕНЯЕМ
      if (store.state.paggination_links.next_page_url == null) {
        document.querySelector('.btn-toFinish').classList.add("choosen");
      } else {
        document.querySelector('.btn-toStart').classList.remove("choosen");
        document.querySelector('.btn-toFinish').classList.remove("choosen");
      }
    }
  } else if (event.target.className.indexOf("toFinish") !== -1) {
    // СТИЛИ МЕНЯЕМ
    document.querySelector('.btn-toFinish').classList.add("choosen");
    document.querySelector('.btn-toStart').classList.remove("choosen");

    window.scrollTo(0, 0);
    await store.dispatch("showAllAdminTasks", { isCompleted: true, link: store.state.paggination_links.last_page_url });
    await store.dispatch("fetchPaggination_AdminTasks", store.state.paggination_links.last_page_url)
  }
}

function formatDate(dateString) {
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('ru-RU', options);
}
function resetTime(date) {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0); // Обнуляем часы, минуты, секунды и миллисекунды
  return newDate;
}
const handleDateClick = (item) => {
  mobile_calendar.value = false; // Закрыть попап
  updateWeek(item); // Обновить неделю
};
  //обновление даты
  const todayTasks = ref()
  const noTasks = ref(false)
  const formattedToday = `${year}-${month}-${day}`;
  const selectedDay = ref(formattedToday); // По умолчанию сегодня
  const dayOfSelectedDay = ref('')
  const selectedDateWeek = ref(formatted_month_Date); // По умолчанию текущая дата в формате "ДД месяц ГГГГ"
  const updateSelectedDateInCalendar = (dayIndex) => {
  const filteredCalendarDays = calendarDays.value.filter(item => item != null);
  const day = filteredCalendarDays[dayIndex - 1];
    noTasks.value = false
  const selectedDateObj = weekDates.value.find(dateObj => dateObj.day === day);
  
  if (selectedDateObj) {
    selectedDay.value = `${selectedDateObj.year}-${String(selectedDateObj.month).padStart(2, '0')}-${String(selectedDateObj.day -1).padStart(2, '0')}`;
    selectedDateWeek.value = formatDate(selectedDay.value);
    dayOfSelectedDay.value = selectedDateObj.day;
    

      todayTasks.value = tasks.value.filter(task => {
      const taskEnd = resetTime(new Date(task.end_date));
      const taskStart = resetTime(new Date(task.start_date));

      return taskEnd >= new Date(selectedDay.value) && taskStart <= new Date(selectedDay.value);
    });
  }
  if (todayTasks.value.length === 0) {
    noTasks.value = true
  }
};
</script>

<style scoped>
/* стили для открытия бургера */

.close-img {
  display: none;
}

@media (max-width: 1024px) {
  .newheader {
    flex: 1;
    margin: 0px;
    padding-right: 20px;
    padding-left: 20px;
    /* width: 100vw;  */
  }

  .newheader_right {
    width: 100vw;
  }

  .newheader__right__empl1 {
    display: none;
  }

  .newheader__left {
    position: fixed;
    background-image: url('../assets/img/m-tasks/mobile-calendar-bg.svg');
    background-size: cover;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    max-height: 100vh;
    overflow: scroll;
  }

  .close-img {
    display: block;
  }

  .newheader__left__bottom {
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    background: none;
    margin: 0px !important;
    padding-right: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10vh;
  }

  .newheader__left__bottom__calendar {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
  }

  .newheader__left__bottom__track {
    margin-left: 0px;
    margin-right: 0px;
  }

  .newheader__left__text {
    display: none;
  }

  .newheader__left__token {
    display: flex;
    margin-left: 0px;
    margin-right: 0px;
  }

  .newheader__left__token__gen {
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    border-radius: 12px;
    background-color: rgb(235, 242, 255);
    border: 1px solid transparent;
    width: 100%;
    max-width: 290px;
    font-weight: 600;
    font-size: 16px;
  }

  .newheader__left__token__block {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 11.7px;
    width: 100%;
    max-width: 290px;
  }

  .newheader__left__token__block input {
    width: 290px;
    max-width: 100%;
    border-radius: 12px;
    height: 56px;
    border: 0 transparent;
    font-size: 14px;
    text-align: center;
  }

  .newheader__left__token__padding {
    padding-top: 27px;
    margin-left: 0px;
  }
}

@media (max-width: 375px) {
  .newheader__left__token__padding {
    padding-top: 20px;
  }
}

.bg-blue {
  background-color: blue;
  /* Синий фон */
  border-radius: 40px;
}

.text-white {
  color: white;
  /* Белый текст */
}

.bg-default {
  background-color: transparent;
  /* Прозрачный фон или другой цвет по умолчанию */
}

.text-default {
  color: black;
  /* Цвет текста по умолчанию */
}

/* Спасаем карточки */
@media (max-width: 490px) {
  .header_TASKS__right__content__block {
    display: none;
  }
}

@media (max-width: 1000px) {
  .newheader__left__token {
    margin-left: 0px;
  }
}

@media (max-width: 1375px) {
  .newheader__left__token__block {
    margin-left: 10px;
  }
}

@media (max-width: 1000px) {
  .newheader__left__token__block {
    margin-left: 0px;
  }
}

.error-message {
  color: var(--main-blue-color);
  font-weight: 500;
  padding-top: 2em;
}
.selected-day {
  border: 1px solid rgb(5, 107, 240); /* Обводка для выбранного дня */
}
.selected-day-calendar{
  border-radius: 32px;
  margin: auto;
  border: 1px solid rgb(5, 107, 240); /* Обводка для выбранного дня */
}
@media(min-width: 1375px){
  .selected-day-calendar{
    width: 40px;
    height: 40px;
  }
}
.bg-blue {
  background-color: #056BF0;
  border-radius: 40px;
}

.text-white {
  color: white;
  /* Белый текст */
}

.bg-default {
  background-color: transparent;
  /* Прозрачный фон или другой цвет по умолчанию */
}

.text-default {
  color: #000826;
  /* Цвет текста по умолчанию */
}
</style>
